import React, { FC, useCallback, useMemo, useState } from 'react';
import { isEmpty } from 'lodash/fp';
import Linkify from 'react-linkify';
import i18n from 'i18next';

import {
  AvatarContainer,
  Container,
  EyesIcon,
  FileContainer,
  FileName,
  FileType,
  IconWrapper,
  ImageContent,
  ImgWrapper,
  LogoText,
  MainContainer,
  MessageContainer,
  MessageContent,
  MessagePieceWrapper,
  MessageTime,
  SameAuthorMessageContent,
  SenderTitle,
  UerWrapper,
  Wrapper,
} from './styled';
import { FileIcon, IconDownload, IconTick, MessagePiece } from 'static';
import { ReadStatus, Type } from 'store/reducers/chat';
import ImageModal from './ImageModal/ImageModal';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { FilterTooltip } from 'components';
import { useSelector } from 'react-redux';
import { getPeerUsers } from 'store/selectors/chat';
import { getNotificationDate } from 'utils/dates';
import { TFunction } from 'i18next';
import { getSettings } from 'store/selectors/auth';
import { validExtensions } from './utils';
import { FlexBox } from 'components/CommunityMenu/CommunityMenuMembersList/styled';
import { useDispatch } from 'react-redux';
import { openModal } from 'store/actionCreators/modal';
import { ModalTypes } from 'types';

interface MessageProps {
  content: string;
  sameAuthor: boolean;
  isGroup: boolean;
  date: string;
  userId: string;
  sameDay: boolean;
  time: string;
  urlUser: string;
  senderId: string;
  senderLastName: string;
  senderFirstName: string;
  isOpen: boolean;
  openViewMemberSidebar: (userId: string) => void;
  mediaType: Type;
  mediaUrl: string[];
  readStatus: ReadStatus[];
}
const SeeMoreInnerComponent = ({
  readStatus,
  t,
  openViewMemberSidebar,
}: {
  readStatus: ReadStatus[];
  t: TFunction;
  openViewMemberSidebar: (userId: string) => void;
}) => {
  const settings = useSelector(getSettings);
  const peerUsers = useSelector(getPeerUsers);
  const getUserProfile = (userId: string) =>
    peerUsers.find((user) => user.userId === userId);

  return (
    <div>
      {readStatus?.length > 0 ? (
        readStatus.map(({ userId, readAt }) => {
          const userProfile = getUserProfile(userId);
          const name = `${
            userProfile.firstName.charAt(0).toUpperCase() +
              userProfile.firstName.slice(1).toLowerCase() || ''
          } ${
            userProfile.lastName.charAt(0).toUpperCase() +
              userProfile.lastName.slice(1).toLowerCase() || ''
          }`.trim();

          return (
            <UerWrapper key={userId} className="divider-y">
              <UerWrapper.Text>
                {getNotificationDate(
                  t,
                  readAt,
                  name,
                  true,
                  settings.timeFormat,
                  i18n.language === 'en',
                )}
              </UerWrapper.Text>
            </UerWrapper>
          );
        })
      ) : (
        <UerWrapper>
          <UerWrapper.Text>{t('chat.noRead')}</UerWrapper.Text>
        </UerWrapper>
      )}
    </div>
  );
};
interface MediaRendererProps {
  mediaUrl: string;
  handleImageClick: (url: string, name: string) => void;
}

export const MediaRenderer: React.FC<MediaRendererProps> = ({
  mediaUrl,
  handleImageClick,
}) => {
  const renderMediaContent = useMemo(() => {
    if (!mediaUrl) return null;

    const name = mediaUrl.split('/').pop() || '';
    const type = name.split('.').pop()?.toLowerCase() || '';

    const isImage = validExtensions.includes(type);
    const isFile = !isImage;

    if (isImage) {
      return (
        <ImageContent
          src={mediaUrl}
          alt={name}
          onClick={() => handleImageClick(mediaUrl, name)}
        />
      );
    }

    if (isFile) {
      return (
        <FileContainer href={mediaUrl} target="_blank" rel="noreferrer">
          <FileIcon width={26} height={26} />
          {name && <FileName>{name.substring(name.indexOf('_') + 1)}</FileName>}
          {type && <FileType>.{type}</FileType>}
        </FileContainer>
      );
    }

    return null;
  }, [mediaUrl, handleImageClick]);

  return renderMediaContent;
};

const Message: FC<MessageProps> = ({
  content,
  sameAuthor,
  isGroup,
  sameDay,
  time,
  urlUser,
  senderId,
  isOpen,
  senderFirstName,
  senderLastName,
  userId,
  openViewMemberSidebar,
  mediaType,
  mediaUrl,
  readStatus,
}) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState('');
  const [modalCaption, setModalCaption] = useState('');
  const isReverse = senderId !== userId;
  const { t } = useTranslation();

  const handleDownloadImage = async (e: MouseEvent, url: string) => {
    e.stopPropagation();

    const name = url.split('/').pop();
    await fetch(url)
      .then((res) => res.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        const bloburl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = bloburl;
        link.setAttribute('download', name.substring(name.indexOf('_') + 1));
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        toast.error(t('errors.downloadFail'));
        console.log('catch', err);
      });
  };

  const linkifyDecorator = useCallback(
    (decoratedHref, decoratedText, key) => (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={decoratedHref}
        key={key}
      >
        {decoratedText}
      </a>
    ),
    [],
  );

  const messageContent = useMemo(
    () =>
      content && (
        <Linkify componentDecorator={linkifyDecorator}>
          <MessageContent>{content}</MessageContent>
        </Linkify>
      ),
    [content, linkifyDecorator],
  );

  const messageTime = useMemo(
    () => (
      <MessageTime reverse={isReverse}>
        <p>{time}</p>
        {!isReverse && (
          <FilterTooltip
            InnerComponent={
              <SeeMoreInnerComponent
                readStatus={readStatus}
                t={t}
                openViewMemberSidebar={openViewMemberSidebar}
              />
            }
            position="bottom-center"
            tooltipStyle={{
              maxWidth: 380,
              width: 'auto',
              maxHeight: 250,
              overflow: 'auto',
              zIndex: 150,
            }}
            isVisible={visible}
            closeTooltip={() => setVisible(false)}
          >
            <span
              onMouseEnter={() => !visible && setVisible(true)}
              style={{ cursor: 'pointer', display: 'flex', gap: '6px' }}
            >
              <IconTick
                width={14}
                height={10}
                color={isEmpty(readStatus) ? '#8298AB' : '#41A2EF'}
              />
              {!isEmpty(readStatus) && <EyesIcon />}
            </span>
          </FilterTooltip>
        )}
      </MessageTime>
    ),
    [isReverse, time, visible, readStatus],
  );

  const handleImageClick = (src: string, alt: string) => {
    setModalImage(src);
    setModalCaption(alt);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSidebar = () => {
    dispatch(
      openModal({
        type: ModalTypes.FILE_VIEW,
        data: {
          urls: mediaUrl,
          handleDownloadImage: handleDownloadImage,
          handleImageClick: handleImageClick,
        },
      }),
    );
  };

  const mediaContent = useMemo(() => {
    if (Array.isArray(mediaUrl) && mediaUrl.length > 0) {
      if (mediaUrl.length > 1) {
        const firstImage = mediaUrl[0];
        return (
          <Container onClick={handleSidebar}>
            <ImgWrapper>
              <p>{'+' + (mediaUrl.length - 1)}</p>
            </ImgWrapper>
            <IconWrapper onClick={(e) => handleDownloadImage(e, firstImage)}>
              <IconDownload width="16" height="16" />
              <p>{t('common.download')}</p>
            </IconWrapper>
            <MediaRenderer mediaUrl={firstImage} handleImageClick={() => {}} />
          </Container>
        );
      } else {
        return mediaUrl.map((url, index) => (
          <Container key={index}>
            <IconWrapper onClick={(e) => handleDownloadImage(e, url)}>
              <IconDownload width="16" height="16" />
              <p>{t('common.download')}</p>
            </IconWrapper>
            <MediaRenderer mediaUrl={url} handleImageClick={handleImageClick} />
          </Container>
        ));
      }
    }
    return null;
  }, [mediaUrl, handleImageClick, handleDownloadImage, t]);

  const firstName = useMemo(
    () =>
      senderFirstName?.charAt(0)?.toUpperCase() +
        senderFirstName?.slice(1)?.toLowerCase() || '',
    [senderFirstName],
  );

  const lastName = useMemo(
    () =>
      senderLastName?.charAt(0)?.toUpperCase() +
        senderLastName?.slice(1)?.toLowerCase() || '',
    [senderLastName],
  );

  if (sameAuthor && sameDay) {
    return (
      <SameAuthorMessageContent reverse={isReverse} isOpen={isOpen}>
        <Wrapper reverse={isReverse} isOpen={isOpen}>
          <MessageContainer extraPadding={true} reverse={isReverse}>
            {isGroup && (
              <SenderTitle onClick={() => openViewMemberSidebar(senderId)}>
                {firstName + ' ' + lastName}
              </SenderTitle>
            )}
            <FlexBox gap={'10px'} style={{ flexDirection: 'column' }}>
              {mediaContent}
              {messageContent}
            </FlexBox>
          </MessageContainer>
        </Wrapper>

        {messageTime}
        <ImageModal
          isOpen={isModalOpen}
          imgSrc={modalImage}
          altText={modalCaption}
          onClose={closeModal}
        />
      </SameAuthorMessageContent>
    );
  }

  return (
    <MainContainer reverse={isReverse} isOpen={isOpen} aline={mediaUrl.length}>
      <Wrapper reverse={isReverse} isOpen={isOpen} aline={mediaUrl.length}>
        <MessagePieceWrapper reverse={isReverse}>
          <MessagePiece />
        </MessagePieceWrapper>
        <AvatarContainer
          extraHeight={false}
          onClick={() => openViewMemberSidebar(senderId)}
        >
          {isEmpty(urlUser) ? (
            <LogoText size="46px">
              {senderFirstName?.charAt(0) + senderLastName?.charAt(0)}
            </LogoText>
          ) : (
            <img src={urlUser} />
          )}
        </AvatarContainer>
        <MessageContainer reverse={isReverse}>
          {isGroup && (
            <SenderTitle onClick={() => openViewMemberSidebar(senderId)}>
              {firstName + ' ' + lastName}
            </SenderTitle>
          )}
          <FlexBox gap={'10px'} style={{ flexDirection: 'column' }}>
            {mediaContent}
            {messageContent}
          </FlexBox>
        </MessageContainer>
      </Wrapper>
      {messageTime}
      <ImageModal
        isOpen={isModalOpen}
        imgSrc={modalImage}
        altText={modalCaption}
        onClose={closeModal}
      />
    </MainContainer>
  );
};

export default React.memo(Message);
