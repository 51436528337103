import { ChatResponse, Conversation } from 'store/reducers/chat';
import { axiosInstance as axios } from './index';
import { default as axiosInstance } from 'axios';
import { validImageSize } from 'pages/PersonalChat/Chat';

export const getUnSeenChatSeenCount = async (
  userId: string,
  communityId?: string,
) => {
  const params = {};

  if (communityId) params['communityId'] = communityId;
  if (userId) params['userId'] = userId;

  const { data } = await axios.get('/getUnreadCountTotal', {
    params,
  });
  return data;
};

export const getChatUsers = async ({
  userId,
  communityId,
  unseen,
  search,
}: {
  userId: string;
  communityId?: string;
  unseen: boolean;
  search?: string;
}): Promise<ChatResponse> => {
  const params: Record<string, any> = {};

  if (unseen) params['unseen'] = unseen;
  if (communityId) params['communityId'] = communityId;
  if (search) params['search'] = search;

  const { data } = await axios.get<ChatResponse>(
    `/userId/${userId}/getUserChatList`,
    {
      params,
    },
  );
  return data;
};

export type SendMessagePayload = {
  userId: string;
  isGroup?: boolean;
  chatId: string;
  content: string;
  type: 'text' | 'image' | 'file';
  communityId?: string;
  receiverId?: string;
  media?: File[] | null;
  mediaDetail?: {
    filename: string;
    mimetype: string;
    size: number;
    ext: string;
  }[];
  onSuccess: () => void;
};

export const sendMessage = async (payload: SendMessagePayload) => {
  const { userId, isGroup, ...messageData } = payload;
  const formData = new FormData();
  formData.append('content', messageData.content || '');
  formData.append('type', payload.type);
  if (payload.chatId) {
    formData.append('chatId', payload.chatId);
  }
  if (payload.receiverId) {
    formData.append('receiverId', payload.receiverId);
  }
  if (payload.communityId) {
    formData.append('communityId', payload.communityId);
  }

  if (
    payload.media !== null &&
    payload.media.length === 1 &&
    validImageSize(payload.media[0])
  ) {
    formData.append('media', payload.media[0]);
  }

  if (payload.media.length !== 1 || !validImageSize(payload.media[0])) {
    formData.append('mediaDetail', JSON.stringify(payload.mediaDetail));
  }

  const endpoint = isGroup
    ? `/userId/${userId}/sendMessageToGroup`
    : `/userId/${userId}/sendMessages`;

  const response = await axios.post(endpoint, formData);
  const presignedUrls = response.data?.presignedUrls;

  if (Array.isArray(presignedUrls) && presignedUrls.length > 0) {
    const uploadPromises = presignedUrls.map((url, index) => {
      if (url && payload.media[index]) {
        return axiosInstance.put(url, payload.media[index]);
      }
      return Promise.reject(new Error('URL or media file missing'));
    });

    try {
      await Promise.all(uploadPromises);
      console.log('All files uploaded successfully');
    } catch (error) {
      console.error('Error during file upload:', error);
    }
  }

  return response.data;
};

export const getChatSearchUsers = async ({ userId, search }) => {
  const params = {};

  params['search'] = search || '';

  const { data } = await axios.get(`/userId/${userId}/chatSearch`, {
    params,
  });
  return data;
};

export const getConversation = async (
  userId: string,
  receiverId: string,
  chatId: string,
  limit?: number,
  page?: number,
) => {
  const url = `/userId/${userId}/getChats/`;
  const params: { [key: string]: string } = {};

  if (chatId) {
    params.chatId = chatId;
  }

  if (receiverId) {
    params.receiverId = receiverId;
  }

  if (limit) {
    params.limit = limit.toString();
  }
  if (page) {
    params.page = page.toString();
  }

  const { data } = await axios.get(url, { params });
  return data;
};

export type CreateChatGroupPayload = {
  chatName: string;
  description: string;
  logo: string;
  members: string[];
};

export type CreateChatGroupResponse = {
  success: boolean;
  message: string;
  chat: Conversation;
};

export const createChatGroup = async (
  userId: string,
  payload: CreateChatGroupPayload,
  communityId: string,
): Promise<CreateChatGroupResponse> => {
  const formData = new FormData();

  formData.append('chatName', payload.chatName);
  formData.append('description', payload.description);

  if (typeof payload.logo === 'object' && payload.logo !== null) {
    formData.append('logo', payload.logo);
  } else if (payload.logo === null) {
    formData.append('logo', '');
  }
  formData.append('members', JSON.stringify(payload.members));

  const { data } = await axios.post(
    `communityId/${communityId}/userId/${userId}/createGroupChat`,
    formData,
  );
  return data;
};

export const deleteChatGroup = async (userId: string, chatId: string) => {
  const { data } = await axios.delete(
    `userId/${userId}/deleteGroupChat/chatId/${chatId}`,
  );
  console.log('data=>>>>>', data);
  return data;
};

export type UpdateChatGroupPayload = {
  chatName: string;
  description: string;
  logo: string | File | null;
  members: string[];
};

export type UpdateChatGroupResponse = {
  success: boolean;
  message: string;
};

export const updateChatGroup = async (
  userId: string,
  chatId: string,
  payload: UpdateChatGroupPayload,
  communityId: string,
): Promise<UpdateChatGroupResponse> => {
  const formData = new FormData();

  // Only append defined fields
  if (payload.chatName) formData.append('chatName', payload.chatName);
  if (payload.description) formData.append('description', payload.description);
  if (payload.members)
    formData.append('members', JSON.stringify(payload.members));

  // Handle logo field
  if (typeof payload.logo === 'object' && payload.logo !== null) {
    formData.append('logo', payload.logo);
  } else if (payload.logo === null) {
    formData.append('logo', '');
  }
  const { data } = await axios.put(
    `communityId/${communityId}/userId/${userId}/updateGroupChat/chatId/${chatId}`,
    formData,
  );
  return data;
};
