import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  useController,
  useForm,
  useWatch,
  Resolver,
  type ValidationMode,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export enum FORMFIELDS {
  MESSAGE = 'message',
  ATTACHMENTS = 'attachments',
}

export interface ChatFormValues {
  [FORMFIELDS.MESSAGE]: string;
  [FORMFIELDS.ATTACHMENTS]: File[];
}

export const useCreateChatForm = (defaultValues: ChatFormValues) => {
  const { t } = useTranslation();

  yup.setLocale({
    mixed: { required: t('errors.required') },
  });

  const resolver: Resolver<ChatFormValues> = yupResolver(
    yup.object().shape({
      [FORMFIELDS.MESSAGE]: yup.string().trim(),
    }),
  );

  const form = useForm<ChatFormValues>({
    resolver,
    defaultValues,
    mode: 'onChange',
  });

  const watchedValues = useWatch({
    name: [FORMFIELDS.MESSAGE, FORMFIELDS.ATTACHMENTS],
    control: form.control,
    defaultValue: defaultValues,
  });

  const { field: attachmentsFieldProps } = useController({
    name: FORMFIELDS.ATTACHMENTS,
    control: form.control,
    defaultValue: defaultValues[FORMFIELDS.ATTACHMENTS],
  });

  return {
    ...form,
    watchedValues,
    attachmentsFieldProps,
  };
};
