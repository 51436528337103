import { defaultButtonStyles } from 'components/Inputs/InvitationLinkInput/styled';
import { NavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { breakpoints } from 'theme';

export const Container = styled.div`
  display: flex;
`;

export const Layout = styled.div`
  min-width: ${({ isOpen = true }) => (isOpen ? '360px' : '0px')};
  width: ${({ isOpen = true }) => (isOpen ? '360px' : '0px')};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.color.dew};
  height: ${({ isOpen = true }) => (isOpen ? '100svh' : '0px')};
  transition: all 0.3s ease;
  z-index: 4;
  /* border-left: ${({ isOpen = true }) => (isOpen ? '1px' : '0px')} solid
    ${({ theme }) => theme.color.baliHai}; */
  box-shadow: 0 4px 12px 0 rgba(48, 70, 89, 0.2);
  @media ${breakpoints.downLg} {
    min-width: ${({ isOpen = true }) => (isOpen ? '300px' : '0px')};
    width: ${({ isOpen = true }) => (isOpen ? '300px' : '0px')};
  }
  /* @media ${breakpoints.downSmPlus} {
    min-width: ${({ isOpen = true }) => (isOpen ? '260px' : '0px')};
    width: ${({ isOpen = true }) => (isOpen ? '260px' : '0px')};
  } */
  @media ${breakpoints.downMd} {
    z-index: 250;
  }
`;

export const SearchWrapper = styled.div`
  background-color: ${({ theme, isShowSearch }) =>
    isShowSearch ? theme.color.brightTurquoise : '#16330014'};
  padding: ${({ isShowSearch }) =>
    isShowSearch ? '0px 0px 0px 2px' : '0px 2px'};
  margin: 0px 16px;
  path {
    stroke: ${({ theme, isShowSearch }) =>
      isShowSearch ? theme.color.pickledBluewood : theme.color.baliHai};
  }
  &:hover {
    background-color: ${({ theme, isShowSearch }) =>
      isShowSearch ? theme.color.brightTurquoise : '#16330021'};
    path {
      stroke: ${({ theme }) => theme.color.pickledBluewood};
    }
  }
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 99999px;
  @media ${breakpoints.downLg} {
    margin: 0px 12px;
  }
`;

export const StyledInputSearch = styled.input`
  display: flex;
  width: ${({ isShowSearch }) => (isShowSearch ? '100px' : '0px')};
  transition: width 0.2s ease-out;
  border-radius: 4px;
  outline: none;
  border: none;
  font-size: 13px;
  font-weight: 400;
  border-radius: 99999px;
  border: ${({ isShowSearch }) => (isShowSearch ? '1px' : '0px')} solid
    ${({ theme }) => theme.color.brightTurquoise};
  color: ${({ theme }) => theme.color.pickledBluewood};
  padding: ${({ isShowSearch }) => (isShowSearch ? '0px 6px' : '0px')};
  height: 26px;
  color: ${({ theme }) => theme.color.pickledBluewood};
  &::placeholder {
    color: ${({ theme }) => theme.color.baliHai};
  }
  &:focus:enabled {
    font-size: 13px;
    border-color: ${({ theme }) => theme.color.brightTurquoise};
    &::placeholder {
      color: transparent;
    }
  }

  &:disabled {
    border-color: ${({ theme }) => theme.color.blackSqueeze};
    color: ${({ theme }) => theme.color.baliHai};

    &::placeholder {
      opacity: 40%;
    }
  }
`;

Layout.Header = styled.div`
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ isOpen }) => (isOpen ? '0px 12px 0 17px' : '0 12px 0 77px')};
  background-color: ${({ theme }) => theme.color.aquaSqueeze};
  position: relative;
`;

Layout.Header.Name = styled.span`
  width: 100%;
  font-size: 16px;
  color: ${({ theme }) => theme.color.pickledBluewood};
  font-weight: bold;
  white-space: nowrap;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
`;

Layout.Header.Close = styled.button`
  ${defaultButtonStyles};
  border-radius: 99999px;
  background-color: #16330014;
  min-width: ${({ width }) => width || '26px'};
  height: ${({ height }) => height || '26px'};
  transform: ${({ rotate }) => (rotate ? 'rotate(180deg)' : 'rotate(0deg)')};
  path {
    stroke: ${({ theme }) => theme.color.baliHai};
  }
  polygon:last-of-type {
    fill: ${({ theme }) => theme.color.baliHai};
  }

  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: ${({ activeColor, theme }) =>
      activeColor ? theme.color.brightTurquoise : '#16330021'};
    path {
      stroke: ${({ theme }) => theme.color.pickledBluewood};
    }
    polygon:last-of-type {
      fill: ${({ theme }) => theme.color.pickledBluewood};
    }
  }

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${({ activeColor, theme }) =>
        activeColor ? theme.color.brightTurquoise : '#16330021'};
      path {
        stroke: ${({ theme }) => theme.color.pickledBluewood};
      }
      polygon:last-of-type {
        fill: ${({ theme }) => theme.color.pickledBluewood};
      }
    `}

  &:disabled {
    opacity: 80%;
    background-color: #16330021;
    path {
      stroke: ${({ theme }) => theme.color.pickledBluewood};
    }
    polygon:last-of-type {
      fill: ${({ theme }) => theme.color.pickledBluewood};
    }
  }

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

Layout.Header.Icon = styled.span`
  position: relative;
  display: inline-block;
  border-radius: 99999px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const PlusBtn = styled.button`
  ${defaultButtonStyles};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding-left: 4px;
  padding: 2px 5px;
  border-radius: 99999px;
  font-family: 'Open Sans', sans-serif;
  color: ${({ theme, isOpen }) =>
    isOpen ? theme.color.pickledBluewood : theme.color.white};
  p {
    font-weight: bold;
    font-size: 13px;
    text-transform: uppercase;
    padding: 0px 6px 1px 0px;
    @media ${breakpoints.downMdPlus} {
      padding: 0px 6px 0px 0px;
      font-size: 12px;
    }
  }
  polygon:last-of-type {
    fill: ${({ theme, isOpen }) =>
      isOpen ? theme.color.pickledBluewood : theme.color.white};
  }
  background-color: ${({ theme, isOpen }) =>
    isOpen ? theme.color.blackSqueeze : theme.color.brightTurquoise};

  &:hover {
    color: ${({ theme, option }) =>
      option ? theme.color.white : theme.color.pickledBluewood};
    background-color: ${({ theme, option }) =>
      option ? theme.color.brightTurquoise : theme.color.blackSqueeze};
    polygon:last-of-type {
      fill: ${({ theme, option }) =>
        option ? theme.color.white : theme.color.pickledBluewood};
    }
  }
`;

export const DropdownContent = styled.div<{ isOpen: boolean }>`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  background-color: ${({ theme }) => theme.color.white};
  box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px,
    rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
  border-radius: 4px;
  z-index: 1000;
  top: 30px;
  right: -26px;
  gap: 2px;
  // overflow: auto;
  position: absolute;
  min-width: max-content;
  padding: 6px;
  will-change: transform;
  width: 336px;
  @media ${breakpoints.downLg} {
    width: 276px;
  }
  /* @media ${breakpoints.downSmPlus} {
    width: 242px;
  } */
  & > .scrollbar-container {
    max-height: 500px;
  }
`;

export const LoaderWrapper = styled.div`
  width: 170px;
  height: 120px;
  display: flex;
  align-items: center;
`;

export const DropdownItem = styled.div`
  color: ${({ theme }) => theme.color.pickledBluewood};
  display: flex;
  padding: 36px 12px;
  @media ${breakpoints.downMdPlus} {
    padding: 22px 8px;
  }
  cursor: pointer;
  max-width: 324px;
  overflow: hidden;
  align-items: center;
  text-overflow: ellipsis;
  justify-content: space-between;
  &:hover {
    background-color: #ebf2f7;
  }
  @media ${breakpoints.downLg} {
    max-width: 266px;
  }
  ${({ selected }) =>
    selected && {
      backgroundColor: '#ebf2f7',
    }};
`;

export const FlexBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${({ gap }) => gap || '0px'};
`;

export const DropdownItemImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 14px;
  img {
    width: 40px;
    object-fit: cover;
    height: 40px;
    border-radius: 9999px;
  }
  @media ${breakpoints.downLg} {
    margin-right: 12px;
  }
  /* @media ${breakpoints.downSmPlus} {
    margin-right: 10px;
  } */
`;

DropdownItemImage.LogoText = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 40px;
  font-size: 15px;
  border-radius: 9999px;
  border: 2px solid ${({ theme }) => theme.color.brightTurquoise};
  line-height: 17px;
  color: ${({ theme }) => theme.color.baliHai};
  width: 40px;
  text-transform: capitalize;
`;

export const DropdownItemName = styled.p`
  display: block;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  text-decoration: none;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const SelectionOption = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 16px;
  width: 100%;
  padding: 0px 12px 0px 12px;
`;

export const UserList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  padding: 0px 6px 0px 6px;
  overflow: auto;
  min-height: 50vh;
  & > .scrollbar-container {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    @media ${breakpoints.downLg} {
      row-gap: 6px;
    }
  }
  @media ${breakpoints.downLg} {
    padding: 0px 4px 0px 4px;
  }
  a {
    text-decoration: none;
    &:hover div {
      color: ${({ theme }) => theme.color.pickledBluewood};
    }
  }
`;

UserList.Item = styled(NavLink)`
  position: relative;
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  align-items: center;
  background-color: ${({ theme, isActive, isUnseen }) =>
    isActive || isUnseen ? theme.color.blackSqueeze : theme.color.aquaSqueeze};
  padding: 36px 12px;
  @media ${breakpoints.downMdPlus} {
    padding: 22px 8px;
  }
  border-left: ${({ theme, isActive }) =>
    isActive ? '4px solid #33ABA7' : '4px solid transparent'};
  &:hover {
    border-left: 4px solid #33aba7;
    background-color: #ebf2f7;
  }

  div {
    color: ${({ theme, isActive }) => theme.color.pickledBluewood};
  }
`;

UserList.Item.Icon = styled.div`
  display: flex;
  position: absolute;
  width: 40px;
  height: 40px;
  img {
    border-radius: 99999px;
    object-fit: cover;
    width: 40px;
    height: 40px;
    ${({ iconStyles }) => iconStyles};
  }
`;

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 56px;
  justify-content: center;
  gap: 6px;
  @media ${breakpoints.downLg} {
    padding-left: 48px;
    gap: 4px;
  }
  min-height: 38px; ;
`;

UserInfo.FirstRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

UserInfo.FirstRow.name = styled.p`
  font-weight: bold;
  white-space: nowrap;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  font-size: 14px;
  text-align: left;
`;

export const Selected = styled.span`
  background-color: ${({ theme }) => theme.color.brightTurquoise};
  color: ${({ theme }) => theme.color.white};
`;

UserInfo.FirstRow.count = styled.span`
  background-color: #ff6c5b;
  width: 18px;
  border-radius: 99999px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.color.white};
  font-size: 10px;
  margin-left: 20px;
  font-weight: 700;
  @media ${breakpoints.downLgPlus} {
    margin-left: 14px;
  }
`;

UserInfo.SecondRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

UserInfo.SecondRow.LastMessage = styled.span`
  color: ${({ theme }) => theme.color.pickledBluewood};
  width: 100%;
  display: flex;
  font-size: 11px;
  font-weight: 600;
  white-space: nowrap;
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  text-align: left;
`;

UserInfo.SecondRow.Time = styled.p`
  font-size: 10px;
  margin-left: 20px;
  z-index: 10;
  font-weight: ${({ bold }) => (bold ? '800' : '600')};
  white-space: nowrap;
  @media ${breakpoints.downLgPlus} {
    margin-left: 14px;
  }
`;

export const PlaceholderContainer = styled.p`
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-top: 12px;
  gap: 4px;
  padding: 20px 16px 0px 16px;
  width: 100%;
  color: ${({ theme }) => theme.color.pickledBluewood};
  font-size: 16px;
  font-weight: 600;
  @media ${breakpoints.downMdPlus} {
    padding: 20px 4px 0px 4px;
  }
`;
