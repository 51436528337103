import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Resolver, useForm } from 'react-hook-form';
import { setLocale } from 'yup';
import { useTranslation } from 'react-i18next';

export enum FIELDS {
  GROUP_NAME = 'name',
  ABOUT = 'about',
  IMAGE = 'logo',
}

export type CreateGroupValues = {
  [FIELDS.GROUP_NAME]: string;
};

export type CreateGroupWithDescriptionValues = CreateGroupValues & {
  [FIELDS.ABOUT]: string;
};

export const useCreateGroupForm = (chat, defaultValues) => {
  const { t } = useTranslation();

  const createGroupSchema = yup.object().shape({
    [FIELDS.GROUP_NAME]: yup.string().required().min(3).max(30).trim(),
  });

  const createGroupWithDescriptionSchema = yup.object().shape({
    [FIELDS.GROUP_NAME]: yup.string().required().min(3).max(30).trim(),
    [FIELDS.ABOUT]: yup.string().max(240).trim().nullable(),
  });

  setLocale({
    mixed: { required: t('errors.required') },
    string: {
      min: ({ min }) => t('errors.min', { value: min }),
      max: ({ max }) => t('errors.max', { value: max }),
    },
  });

  const resolver: Resolver<
    CreateGroupValues | CreateGroupWithDescriptionValues
  > = yupResolver(chat ? createGroupWithDescriptionSchema : createGroupSchema);

  return useForm<CreateGroupValues | CreateGroupWithDescriptionValues>({
    resolver,
    defaultValues: {
      [FIELDS.GROUP_NAME]: defaultValues?.chatName || '',
      [FIELDS.ABOUT]: defaultValues?.description || '',
    },
  });
};
