import styled, { css } from 'styled-components';
import { pulse } from '../CommunityMenuList/styled';
import { SendMessageIcon } from 'static';

export const StyledMenuList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 2px;
  padding-top: 2px;
  list-style-type: none;
  li:last-of-type {
    margin-bottom: 0;
  }
`;
export const IconSendMessage = styled(SendMessageIcon)`
  width: 16px;
  height: 16px;
  z-index: 10;
  path {
    stroke: ${({ theme }) => theme.color.pickledBluewood};
    stroke-width: 2;
  }
`;

export const ChatIconWrapper = styled.div`
  display: none;
  border-radius: 9999999px;
  padding: 1px 6px;
  &:hover {
    &::before {
      content: '';
      position: absolute;
      border-radius: 99999px;
      display: block;
      width: 24px;
      height: 22px;
      right: 11px;
      top: 0px;
      border: 1px solid;
      background: ${({ theme }) => theme.color.brightTurquoise};
      z-index: 5;
    }
  }

  &:hover ${IconSendMessage} {
    path {
      stroke: ${({ theme }) => theme.color.white};
      stroke-width: 2;
    }
  }
`;

export const StyledMenuListItem = styled.li`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  padding: 3px 10px 3px 38px;
  align-items: center;
  font-size: 13px;
  border-radius: 6px;
  color: ${({ theme }) => theme.color.pickledBluewood};
  &:before {
    content: '';
    position: absolute;
    top: 10px;
    left: 19px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: ${({ theme, isOnline }) =>
      isOnline ? theme.color.brightTurquoise : theme.color.casper};
  }

  ${({ hasGreenDot }) =>
    hasGreenDot &&
    css`
      color: ${({ theme }) => theme.color.brightTurquoise};
      background-color: ${({ theme }) => theme.color.blackSqueeze};
      &:after {
        content: '';
        position: absolute;
        display: block;
        width: 2px;
        height: 14px;
        left: 5px;
        background-color: ${({ theme }) => theme.color.pickledBluewood};
      }
    `}

  &:hover {
    color: ${({ theme }) => theme.color.brightTurquoise};
    background-color: ${({ theme }) => theme.color.blackSqueeze};
    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 2px;
      height: 14px;
      left: 5px;
      background-color: ${({ theme }) => theme.color.pickledBluewood};
    }
    ${ChatIconWrapper} {
      display: flex;
    }
  }
  ${({ isActive }) =>
    isActive &&
    css`
      color: ${({ theme }) => theme.color.brightTurquoise};
      background-color: ${({ theme }) => theme.color.blackSqueeze};
      &:after {
        content: '';
        position: absolute;
        display: block;
        width: 2px;
        height: 14px;
        left: 5px;
        background-color: ${({ theme }) => theme.color.pickledBluewood};
      }
      ${ChatIconWrapper} {
        display: flex;
      }
    `}
`;

export const StyledName = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const NameAndBadgeWrapper = styled.div`
  display: flex;
`;

export const FlexBox = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${({ gap }) => gap || '2px'};
`;

export const MessageCount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  background-color: ${({ theme }) => theme.color.brightTurquoise};
  border-radius: 99999px;
  animation: ${pulse} 1s infinite;
`;

export const CountBadge = styled.div`
  display: flex;
  font-size: 10px;
  line-height: normal;
  border-radius: 9999999px;
  color: ${({ theme }) => theme.color.white}; ;
`;
