import React, {
  useState,
  useRef,
  useEffect,
  ReactNode,
  useImperativeHandle,
  ForwardedRef,
} from 'react';
import ReactDOM from 'react-dom';
import {
  DropDownRoot,
  DropDownTrigger,
  MenuContainer,
  MenuWrapper,
} from './styled';

type Position = 'top' | 'bottom';

interface DropdownMenuProps {
  trigger: ReactNode;
  children: ReactNode;
  position?: Position;
}

// eslint-disable-next-line react/display-name
const DropdownMenu = React.forwardRef<
  { closeMenu: () => void },
  DropdownMenuProps
>(({ trigger, children, position = 'bottom' }, ref) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [coords, setCoords] = useState<{ top: number; left: number }>({
    top: 0,
    left: 0,
  });

  const triggerRef = useRef<HTMLDivElement | null>(null);
  const menuRef = useRef<HTMLDivElement | null>(null);

  const toggleMenu = () => {
    if (triggerRef.current) {
      const rect = triggerRef.current.getBoundingClientRect();
      const calculatedPosition = calculatePosition(rect, position);
      setCoords(calculatedPosition);
    }
    setIsOpen(!isOpen);
  };

  const closeMenu = () => setIsOpen(false);

  useImperativeHandle(ref as ForwardedRef<{ closeMenu: () => void }>, () => ({
    closeMenu,
  }));

  const handleClickOutside = (event: MouseEvent) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target as Node) &&
      !triggerRef.current?.contains(event.target as Node)
    ) {
      closeMenu();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isOpen && triggerRef.current) {
      const handleResize = () => {
        const rect = triggerRef.current.getBoundingClientRect();
        const calculatedPosition = calculatePosition(rect, position);
        setCoords(calculatedPosition);
      };

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [isOpen, position]);

  const calculatePosition = (rect: DOMRect, position: Position) => {
    switch (position) {
      case 'top':
        return { top: rect.top - 150, left: rect.left };
      case 'bottom':
      default:
        return { top: rect.bottom + 6, left: rect.left - 200 };
    }
  };

  return (
    <DropDownRoot ref={triggerRef}>
      <DropDownTrigger onClick={toggleMenu} isOpen={isOpen}>
        {trigger}
      </DropDownTrigger>

      {isOpen &&
        ReactDOM.createPortal(
          <MenuContainer
            ref={menuRef}
            style={{
              position: 'fixed',
              top: `${coords.top}px`,
              left: `${coords.left}px`,
              zIndex: 9999,
            }}
          >
            <MenuWrapper>{children}</MenuWrapper>
          </MenuContainer>,
          document.body,
        )}
    </DropDownRoot>
  );
});

export default DropdownMenu;
