import styled from 'styled-components';
import { breakpoints } from 'theme';

export const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

export const OuterDiv = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
`;

export const InnerDiv = styled.div<{ showMessages: boolean }>`
  position: relative;
  margin: auto;
  transition: all 0.3s ease-in-out;
  max-width: 800px;
  @media ${breakpoints.downLgPlus} {
    max-width: ${({ isOpen }) => (isOpen ? '520px' : '620px')};
  }
  opacity: ${(props) => (props.showMessages ? 1 : 0)};
`;

export const ScrollButton = styled.button`
  position: absolute;
  bottom: 1rem;
  left: 50%;
  width: 7rem;
  border-radius: 0.5rem;
  font-size: 0.875rem;
  background-color: #ef4444;
  color: #ffffff;
  transition: all 0.3s ease-in-out;
  z-index: 500;
`;
