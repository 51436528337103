import React, {
  CSSProperties,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
} from 'react';
import { v4 as uuid } from 'uuid';
import { MenuItem, PlusButton, StyledPlusIcon, Tooltip } from 'components';
import { CommunityRoles } from 'types';

import {
  PlusButtonWrapper,
  StyledArrow,
  StyledList,
  StyledMenuListHeader,
} from './styled';
import { Layout } from 'pages/PersonalChat/Chat/styled';
import { LeftCloseArrow } from 'static';

interface CommunityMenuListWrapperPops {
  title: string;
  icon: ReactNode;
  to?: string;
  isAddButtonContained?: boolean;
  isPlusIcon?: boolean;
  toolTipText?: string;
  userRole?: CommunityRoles;
  children: JSX.Element | JSX.Element[];
  onClick?: () => void;
  iconStyles?: CSSProperties;
  showArrow?: boolean;
  handleOnArrowClick?: () => void;
  arrowTooltipText?: string;
}
export const tooltipProps = {
  tooltipStyle: {
    fontSize: 12,
    maxWidth: 200,
  },
};
const tooltipArrowProps = (text: string) => ({
  tooltipStyle: {
    fontSize: 12,
    maxWidth: 200,
  },
  text,
});
const CommunityMenuListWrapper: FC<CommunityMenuListWrapperPops> = ({
  title,
  icon,
  isAddButtonContained,
  children,
  to = '',
  isPlusIcon,
  userRole,
  iconStyles,
  toolTipText,
  onClick = () => {},
  showArrow,
  handleOnArrowClick = () => {},
  arrowTooltipText,
}) => {
  const handleOnClick = () => {
    onClick();
  };
  const isAdminOrOwner =
    userRole === CommunityRoles.ADMIN || userRole === CommunityRoles.OWNER;

  return (
    <StyledList>
      <StyledMenuListHeader isAddButtonNotContained={!isAddButtonContained}>
        <MenuItem
          to={to}
          title={title}
          icon={icon}
          left
          iconStyles={iconStyles}
        />
        {isPlusIcon && isAdminOrOwner && (
          <StyledPlusIcon onClick={handleOnClick} />
        )}
        {isAddButtonContained && isAdminOrOwner && (
          <PlusButtonWrapper>
            <PlusButton
              text={toolTipText}
              tooltipStyle={tooltipProps.tooltipStyle}
              position="top-center"
              width="28px"
              contained={isAddButtonContained}
              onClick={onClick}
              adjustToolTipPosition={{ x: -0.7 }}
            />
          </PlusButtonWrapper>
        )}
        {showArrow && !isAdminOrOwner && (
          <Tooltip
            {...tooltipArrowProps(arrowTooltipText)}
            offset={8}
            position="top-center"
            isVisible
          >
            <StyledArrow onClick={handleOnArrowClick}>
              <Layout.Header.Close
                mr="0px"
                width="28px"
                height="28px"
                stroke="#304659"
                hoverStroke="white"
              >
                <LeftCloseArrow width={20} height={20} strokeWidth="2.4" />
              </Layout.Header.Close>
            </StyledArrow>
          </Tooltip>
        )}
      </StyledMenuListHeader>
      {children}
    </StyledList>
  );
};

export default CommunityMenuListWrapper;
