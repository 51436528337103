import React, { FC } from 'react';

import { DefaultCommunityImage } from 'components';

import {
  StyledCommunityTitleBlock,
  StyledCommunityImage,
  StyledCommunityTitle,
} from './styled';

interface CommunityTitleProps {
  title: string;
  image: string | null;
  isMenuOpen: boolean;
  showTitle?: boolean;
  frame?: boolean;
}

const CommunityTitle: FC<CommunityTitleProps> = ({
  title,
  image,
  isMenuOpen,
  showTitle = true,
  frame,
}) => (
  <StyledCommunityTitleBlock isMenuOpen={isMenuOpen}>
    {image ? (
      <StyledCommunityImage src={image} alt="" />
    ) : (
      <DefaultCommunityImage
        title={showTitle ? title.charAt(0).toUpperCase() : title}
        frame={frame}
      />
    )}
    {showTitle && <StyledCommunityTitle>{title}</StyledCommunityTitle>}
  </StyledCommunityTitleBlock>
);

export default CommunityTitle;
