import React, { FC } from 'react';

import { GreenPlusIcon, IconMinus } from 'static';

import { StyledButton } from './styled';
import Tooltip from 'components/Tooltip';
import { Placement } from 'react-laag';

interface PlusButtonProps {
  width?: string;
  contained?: boolean;
  disabled?: boolean;
  onClick: () => void;
  position?: Placement;
  tooltipStyle?: { [key: string]: string | number };
  text?: JSX.Element | JSX.Element[] | string;
  isVisible?: boolean;
  adjustToolTipPosition?: { [key: string]: string | number };
  isMinusIcon?: boolean;
}

const PlusButton: FC<PlusButtonProps> = ({
  width = '40px',
  contained,
  disabled,
  onClick,
  position,
  text,
  isVisible,
  tooltipStyle,
  adjustToolTipPosition,
  isMinusIcon,
}) =>
  text ? (
    <Tooltip
      position={position}
      tooltipStyle={{ ...tooltipStyle }}
      text={text}
      isVisible={isVisible}
      adjustToolTipPosition={adjustToolTipPosition}
    >
      <StyledButton
        type="button"
        onClick={onClick}
        contained={contained}
        disabled={disabled}
        width={width}
        isMinusIcon={isMinusIcon}
      >
        {isMinusIcon ? (
          <IconMinus width={width} height={width} />
        ) : (
          <GreenPlusIcon width={width} />
        )}
      </StyledButton>
    </Tooltip>
  ) : (
    <StyledButton
      type="button"
      onClick={onClick}
      contained={contained}
      disabled={disabled}
      width={width}
      isMinusIcon={isMinusIcon}
    >
      {isMinusIcon ? (
        <IconMinus width={width} height={width} />
      ) : (
        <GreenPlusIcon width={width} />
      )}
    </StyledButton>
  );

export default PlusButton;
