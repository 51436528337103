import React, {
  FC,
  useEffect,
  useCallback,
  useRef,
  useState,
  useMemo,
} from 'react';
import { noop, isEmpty } from 'lodash/fp';
import { useTranslation } from 'react-i18next';
import {
  Layout,
  PlaceholderContainer,
  ChatLayout,
  RedirectSection,
  RedirectWrapper,
  MessageContainer,
  MessageWrapper,
  StyledShadow,
  Wrapper,
  DivChat,
} from './styled';
import { Divider, Loader } from 'components';
import Placeholder from './Placeholder';
import DateSeparator from './DateSeparator';
import Message from './Message';
import { getDate } from 'utils';
import { getDateForEngLocation, getTimeWithA } from 'utils/dates';
import { useDispatch } from 'react-redux';
import {
  doGetConversation,
  doSendMessage,
  doUpdateConversation,
} from 'store/actionCreators/chat';
import { useSelector } from 'react-redux';
import { getAuthUser, getSettings } from 'store/selectors/auth';
import {
  getChatUserLoading,
  getChat,
  getConversationLoading,
  sendMessageLoading,
  getIsOldChat,
  deleteChatGroupLoading,
} from 'store/selectors/chat';
import ChatInput from '../ChatInput';
import { useInfoPopup } from 'hooks';
import {
  getCurrentMembers,
  getIsMenuOpen,
} from 'store/selectors/currentCommunity';
import { ChatUser, Conversation, Messages } from 'store/reducers/chat';
import { getProfileNameCapital } from 'utils/common';
import Header from './Header';
import { FORMFIELDS, useCreateChatForm } from './form';
import { CommunityUser, ModalTypes } from 'types';
import { VIEW_MEMBER } from 'constants/sidebars';
import { openModal } from 'store/actionCreators/modal';
import i18n from 'i18next';
import { useCommunityNamesById } from 'pages/PushNotification/util';
import { useNavigate } from 'react-router-dom';
import { COMMUNITY_ROUTES, ROUTES } from 'configs';
import {
  doClearCommunity,
  doRequestCommunityForChat,
} from 'store/actionCreators/currentCommunity';
import useChatLazyLoading from 'hooks/useChatLazyLoading';
import { ScrollContainer } from 'components/ScrollContainer/ScrollContainer';

interface ChatProps {
  openUserList: boolean;
  toggleSidebar: noop;
  count: number | null;
  searchLoading: boolean;
  openCreateChatPopUp: () => void;
  talkId: string;
  peerId: string;
  communityId?: string;
}
const AVAILABLE_SIZE = 5 * 1024;
export const validImageSize = (imageFile) =>
  imageFile && Math.round(imageFile.size / 1024) < AVAILABLE_SIZE;
const Chat: FC<ChatProps> = ({
  openUserList,
  toggleSidebar,
  count,
  searchLoading,
  openCreateChatPopUp,
  talkId,
  peerId,
  communityId,
}) => {
  const deleteGroupLoading = useSelector(deleteChatGroupLoading);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isSidebarOpen = useSelector(getIsMenuOpen);
  const { showInfoPopup } = useInfoPopup();
  const dispatch = useDispatch();
  const settings = useSelector(getSettings);
  const chatUserLoading = useSelector(getChatUserLoading);
  const chatList = useSelector(getChat);
  const chatListLoading = useSelector(getConversationLoading);
  const sendMessageLoader = useSelector(sendMessageLoading);
  const user = useSelector(getAuthUser);
  const [chats, setChats] = useState<Conversation | null>(null);
  const flag = useRef<boolean>(false);
  const [selectedPeerUser, setSelectedPeerUser] = useState<ChatUser | null>(
    null,
  );
  const isOldChat = useSelector(getIsOldChat);
  const getChats = (data) => {
    flag.current = true;
    dispatch(
      doUpdateConversation({
        userId: user.userId,
        chatId: talkId,
        receiverId: peerId,
        limit: data.limit,
        page: data.page,
      }),
    );
  };

  const { listRef } = useChatLazyLoading({
    isLoading: chatListLoading,
    loadMessages: getChats,
    pageSize: 20,
    scrollOffset: 1300,
    totalMessages: chats?.messages?.length || 0,
    hasNextPage: chats?.messages && chats.messages.length < chats.messageCount,
  });

  const hasMessages = chats?.messages && chats.messages.length > 0;
  const members = useSelector(getCurrentMembers);
  let member: CommunityUser;
  if (peerId) {
    member = members.find((member) => member.id === peerId);
    if (!member) {
      navigate(`${ROUTES.COMMUNITY}/${communityId}/${COMMUNITY_ROUTES.CHAT}`);
    }
  }

  const defaultValues = {
    message: '',
    attachments: [],
  };

  useEffect(() => {
    if (!communityId && chatList?.communityId?.length) {
      dispatch(
        doRequestCommunityForChat({ communityId: chatList?.communityId[0] }),
      );
    }
    return () => {
      if (!communityId) {
        dispatch(doClearCommunity());
      }
    };
  }, [communityId, chatList?.communityId]);

  useEffect(() => {
    setChats(chatList);
    if (!chatList?.isGroupChat) {
      setSelectedPeerUser(
        chatList?.peerUsers.find((u) => u.userId !== user.userId),
      );
    }
  }, [chatList]);

  useEffect(() => {
    if (peerId || talkId) {
      clearErrors();
      reset(defaultValues);
      //todo
      // getConversationFromDB(talkId).then((res) => {
      // if (res) {
      //   dispatch(doSetConversation.success(res));
      //   setChats(res);
      //   if (!res?.isGroupChat) {
      //     setSelectedPeerUser(
      //       res?.peerUsers.find((user) => user.userId == user.userId),
      //     );
      //   }
      // }
      // if (!res) {
      //   dispatch(
      //     doGetConversation({
      //       userId: user.userId,
      //       chatId: talkId,
      //       receiverId: peerId,
      //       count,
      //     }),
      //   );
      // }
      // });
      // .catch((err) => {});
      setChats(null);
      setSelectedPeerUser(null);
      dispatch(
        doGetConversation({
          userId: user.userId,
          chatId: talkId,
          receiverId: peerId,
          count,
          page: 1,
          limit: 20,
        }),
      );
    }
  }, [talkId, peerId]);

  const openViewMemberSidebar = useCallback(
    (userId: string) => {
      console.log('userId', userId, members);
      if (members?.length === 0) {
        return;
      }
      const user: CommunityUser | undefined = userId
        ? members?.find((member) => member.id === userId)
        : undefined;

      if (!userId) {
        dispatch(
          openModal({
            type: ModalTypes.ABOUT_CHAT_GROUP,
            placement: 'right',
            data: {
              chat: {
                id: talkId,
                chatName: chatList?.chatName,
                description: chatList?.description,
                logo: chatList?.logo || null,
              },
              createdAt: chatList?.createdAt,
              members: chats?.peerUsers,
              communityId: communityId || chatList.communityId,
            },
          }),
        );
      } else {
        dispatch(
          openModal({
            type: VIEW_MEMBER,
            placement: 'right',
            data: user || member,
          }),
        );
      }
    },
    [communityId, members, chatList, chats, talkId],
  );

  const {
    errors,
    register,
    handleSubmit,
    clearErrors,
    control,
    setValue,
    trigger: triggerFormValidation,
    watchedValues,
    attachmentsFieldProps,
    setError,
    reset,
  } = useCreateChatForm(defaultValues);

  const onSendMessage = async (docType: 'document' | 'photo') => {
    flag.current = false;
    handleSubmit(async (data) => {
      const mediaDetails = data[FORMFIELDS.ATTACHMENTS];
      if (
        !data[FORMFIELDS.MESSAGE] &&
        (!mediaDetails || mediaDetails.length === 0)
      ) {
        setError(FORMFIELDS.MESSAGE, {
          type: 'required',
          message: t('errors.required'),
        });
        return;
      }
      const mediaDetail = data[FORMFIELDS.ATTACHMENTS].map((file) => ({
        ext: file.type.split('/')?.[1],
        filename: file.name,
        mimetype: file.type,
        size: file.size / 1000000000,
      }));
      const type =
        data[FORMFIELDS.ATTACHMENTS] && data[FORMFIELDS.ATTACHMENTS].length > 0
          ? docType === 'photo'
            ? 'image'
            : 'file'
          : 'text';

      try {
        dispatch(
          doSendMessage.trigger({
            userId: user.userId,
            chatId: talkId || chats?.id || null,
            content: data[FORMFIELDS.MESSAGE],
            type,
            communityId,
            media: data[FORMFIELDS.ATTACHMENTS],
            isGroup: !!chats?.isGroupChat,
            mediaDetail,
            ...(!chats?.isGroupChat && {
              receiverId: selectedPeerUser?.userId || peerId,
            }),
            onSuccess: () => {
              setValue(FORMFIELDS.MESSAGE, '');
              setValue(FORMFIELDS.ATTACHMENTS, []);
            },
          }),
        );
      } catch (err) {
        console.log('err', err);
        showInfoPopup({
          title: 'Something went wrong',
          message: 'Please try again',
          type: 'error',
          loop: false,
        });
      }
    })();
  };

  const onSubmit = async (type: 'document' | 'photo') => {
    const isValid = await triggerFormValidation();
    if (isValid) onSendMessage(type);
  };

  const scrollToElement = useCallback((id: string) => {
    if (listRef.current) {
      const targetElement = document.getElementById(id);
      if (targetElement && targetElement.nextElementSibling) {
        const nextSibling = targetElement.nextElementSibling as HTMLElement;
        const container = listRef.current;
        if (container) {
          const targetOffset =
            nextSibling.getBoundingClientRect().top -
            container.getBoundingClientRect().top;
          const containerScrollTop = container.scrollTop;

          const stickyOffset = 40;
          container.scrollTop =
            containerScrollTop + targetOffset - stickyOffset;
        }
      }
    }
  }, []);

  const findPeerUser = useCallback(
    (id: string) => {
      return chats?.peerUsers.find((peer) => peer.userId === id);
    },
    [chats],
  );

  const urlUser = useMemo(
    () => (id: string) => {
      const peerUser = findPeerUser(id);
      return peerUser
        ? peerUser.logo || peerUser.smallLogo
        : user?.logo || user?.smallLogo;
    },
    [chats, user],
  );

  const getUserName = useMemo(
    () => (id: string) => {
      const peerUser = findPeerUser(id);
      return peerUser
        ? peerUser.firstName || peerUser.profileName
        : user?.firstName || user?.profileName;
    },
    [chats, user],
  );

  const senderLastName = useMemo(
    () => (id: string) => {
      const peerUser = findPeerUser(id);
      return peerUser
        ? peerUser.lastName || peerUser.profileName
        : user?.lastName || user?.profileName;
    },
    [chats, user],
  );
  const handleDocumentsAttach = async (data) => {
    // TODO For Multiple file upload
    // const newFiles = await Promise.all(
    //   data
    //     .filter(
    //       (i) =>
    //         !watchedValues[FORMFIELDS.ATTACHMENTS].some(
    //           (f) => f.name === i.name,
    //         ),
    //     )
    //     .map((i) => download(i.url, i.name)),
    // );
    // return attachmentsFieldProps.onChange([
    //   ...watchedValues[FORMFIELDS.ATTACHMENTS],
    //   ...newFiles,
    // ]);
  };

  const communityNames = useCommunityNamesById(chatList?.communityId || []);

  const description = `${communityNames}  ${
    chatList?.description ? ' • ' + chatList.description : ''
  }`;

  const chatListComponent: JSX.Element | JSX.Element[] = useMemo(() => {
    if (!chats?.messages.length) {
      return;
    }

    return chats.messages.map((message: Messages, index) => {
      const sameAuthor =
        index > 0 && message?.senderId === chats.messages[index - 1]?.senderId;
      const sameDay =
        index > 0 &&
        getDate(message?.createdAt)?.toLowerCase() ===
          getDate(chats.messages[index - 1]?.createdAt)?.toLowerCase();
      const time = getTimeWithA(message?.createdAt, settings.timeFormat);

      const date =
        i18n.language === 'en'
          ? getDateForEngLocation(message?.createdAt)
          : getDate(message?.createdAt);

      return (
        <React.Fragment key={message.id}>
          {(!sameDay || index === 0) && (
            <DateSeparator date={date} handleScroll={scrollToElement} />
          )}
          <MessageContainer>
            <MessageWrapper
              reverse={message.senderId !== user.userId}
              data-item-key={message.id}
            >
              <Message
                isGroup={chatList?.isGroupChat}
                openViewMemberSidebar={openViewMemberSidebar}
                userId={user.userId}
                isOpen={openUserList}
                urlUser={urlUser(message.senderId)}
                senderFirstName={getUserName(message.senderId)}
                senderLastName={senderLastName(message.senderId)}
                content={message.content}
                date={message.createdAt}
                sameAuthor={sameAuthor}
                time={time}
                sameDay={sameDay}
                mediaType={message.type}
                mediaUrl={message?.mediaUrl}
                senderId={message.senderId}
                readStatus={message.readStatus}
              />
            </MessageWrapper>
          </MessageContainer>
        </React.Fragment>
      );
    });
  }, [
    chats?.messages,
    settings.timeFormat,
    i18n.language,
    scrollToElement,
    openViewMemberSidebar,
    openUserList,
    urlUser,
    getUserName,
    senderLastName,
    user.userId,
  ]);

  return (
    <Layout isOpen={openUserList}>
      {deleteGroupLoading && <Loader type="main" />}
      <Header
        t={t}
        userId={user.userId}
        isSidebarOpen={isSidebarOpen}
        openUserList={openUserList}
        toggleSidebar={toggleSidebar}
        selectedPeerUser={selectedPeerUser}
        communityId={communityId || chatList?.communityId[0]}
        peerId={peerId}
        talkId={talkId}
        isGroup={chatList?.isGroupChat}
        groupName={chatList?.chatName}
        member={member}
        members={chats?.peerUsers}
        description={chatList?.description}
        logo={chatList?.logo}
        headerDesc={description}
        createdAt={chatList?.createdAt}
        admin={chatList?.admin}
        openViewMemberSidebar={openViewMemberSidebar}
        loading={chatListLoading || chatUserLoading}
      />
      <Divider customMargin="0px" />
      {!isEmpty(talkId) || !isEmpty(peerId) ? (
        <ChatLayout>
          <DivChat>
            {chatListLoading || chatUserLoading ? (
              <Loader size="60px" type={null} />
            ) : hasMessages ? (
              <ScrollContainer isOpen={openUserList} outerDiv={listRef}>
                {chatListComponent}
              </ScrollContainer>
            ) : null}
            {!isOldChat && (
              <Wrapper>
                {t('chat.emptyStatus.sendFirstMessage', {
                  recipient: chatList?.isGroupChat
                    ? chatList?.chatName
                    : getProfileNameCapital({
                        firstName:
                          selectedPeerUser?.firstName ||
                          member?.profile?.firstName,
                        lastName:
                          selectedPeerUser?.lastName ||
                          member?.profile?.lastName,
                      }),
                })}
              </Wrapper>
            )}
          </DivChat>
          <RedirectWrapper>
            {/* <StyledShadow /> */}
            <Divider customMargin="0px" />
            <ChatInput
              watchedValues={watchedValues}
              clearErrors={clearErrors}
              attachmentsFieldProps={attachmentsFieldProps}
              sendMessageLoader={sendMessageLoader || chatListLoading}
              isOpen={openUserList}
              onSubmit={onSubmit}
              control={control}
              deleteGroupLoading={deleteGroupLoading}
              errors={errors}
              register={register}
              onDocumentsAttach={handleDocumentsAttach}
              isActiveSend={watchedValues[FORMFIELDS.MESSAGE].length >= 1}
              communityId={communityId}
            />
          </RedirectWrapper>
        </ChatLayout>
      ) : (
        <PlaceholderContainer>
          <Placeholder
            imageUrl=""
            text={t('chat.emptyStatus.noChatSelected.firstLine')}
            linkText={t('chat.emptyStatus.noChatSelected.LinkText')}
            openCreateChatPopUp={openCreateChatPopUp}
          />
        </PlaceholderContainer>
      )}
    </Layout>
  );
};

export default Chat;
