import { useEffect, useRef } from 'react';
import { debounce } from 'lodash';
import { getUpdateConversationLoading } from 'store/selectors/chat';
import { useSelector } from 'react-redux';

const SCROLL_OFFSET = 500;

type UseChatLazyLoadingOptions = {
  isLoading: boolean;
  loadMessages: (options?: any) => void;
  pageSize: number;
  totalMessages: number;
  hasNextPage: boolean;
  scrollOffset?: number;
};

const useChatLazyLoading = ({
  isLoading,
  loadMessages,
  pageSize,
  totalMessages,
  hasNextPage,
  scrollOffset = SCROLL_OFFSET,
}: UseChatLazyLoadingOptions) => {
  const listRef = useRef<HTMLDivElement>(null);
  const chatLoading = useSelector(getUpdateConversationLoading);
  const loadedPagesRef = useRef<Set<number>>(new Set());

  const handleScroll = debounce(() => {
    const container = listRef.current;
    if (container) {
      const scrollTop = container.scrollTop;
      const nextPage = Math.ceil(totalMessages / pageSize) + 1;
      if (
        scrollTop <= scrollOffset &&
        !isLoading &&
        !chatLoading &&
        hasNextPage &&
        !loadedPagesRef.current.has(nextPage)
      ) {
        loadedPagesRef.current.add(nextPage);
        loadMessages({
          page: nextPage,
          limit: pageSize,
        });
      }
    }
  }, 50);

  useEffect(() => {
    const container = listRef.current;
    if (!container) return;

    const wheelHandler = (event: WheelEvent) => {
      event.preventDefault();
      container.scrollTop += event.deltaY * 0.2;
    };
    container.addEventListener('scroll', handleScroll);
    container.addEventListener('wheel', wheelHandler);

    return () => {
      container.removeEventListener('scroll', handleScroll);
      container.removeEventListener('wheel', wheelHandler);
      loadedPagesRef.current.clear();
    };
  }, [isLoading, totalMessages, hasNextPage, scrollOffset, handleScroll]);

  return { listRef };
};

export default useChatLazyLoading;
