import React, { useRef, useEffect, useState, useCallback } from 'react';
import { Container, OuterDiv, InnerDiv, ScrollButton } from './styled';
import { sendMessageLoading } from 'store/selectors/chat';
import { useSelector } from 'react-redux';
import { ScrollBottomBtn } from 'pages/PersonalChat/Chat/styled';
import { LeftCloseArrow } from 'static';

export const ScrollContainer = ({
  children,
  isOpen,
  outerDiv,
}: {
  children: React.ReactNode;
  isOpen: boolean;
  outerDiv: React.MutableRefObject<HTMLDivElement>;
}) => {
  const sendMessageLoader = useSelector(sendMessageLoading);
  const innerDiv = useRef<HTMLDivElement | null>(null);
  const prevInnerDivHeight = useRef(
    innerDiv.current && innerDiv.current.clientHeight,
  );
  const [showBottomBtn, setShowBottomBtn] = useState(false);
  const [showMessages, setShowMessages] = useState(false);
  const [showScrollButton, setShowScrollButton] = useState(false);

  const getScrollDimensions = () => {
    const outerDivHeight = outerDiv.current.clientHeight;
    const innerDivHeight = innerDiv.current.clientHeight + 28;
    const outerDivScrollTop = outerDiv.current.scrollTop;
    return { outerDivHeight, innerDivHeight, outerDivScrollTop };
  };

  const handleScroll = () => {
    const { innerDivHeight, outerDivHeight } = getScrollDimensions();
    const outerDivScrollTop = outerDiv.current.scrollTop;

    if (innerDivHeight - outerDivHeight - outerDivScrollTop > 100) {
      setShowBottomBtn(true);
    } else {
      setShowBottomBtn(false);
      setShowScrollButton(false);
    }
  };

  useEffect(() => {
    const outerDivCurrent = outerDiv.current;
    outerDivCurrent.addEventListener('scroll', handleScroll);

    return () => {
      outerDivCurrent.removeEventListener('scroll', handleScroll);
    };
  }, [outerDiv.current]);

  useEffect(() => {
    const { innerDivHeight, outerDivHeight } = getScrollDimensions();
    const outerDivScrollTop = Math.ceil(outerDiv.current.scrollTop);
    if (
      !prevInnerDivHeight.current ||
      outerDivScrollTop === prevInnerDivHeight.current - outerDivHeight
    ) {
      outerDiv.current.scrollTo({
        top: (innerDivHeight ?? 0) - (outerDivHeight ?? 0),
        left: 0,
        behavior: prevInnerDivHeight.current ? 'smooth' : 'auto',
      });
      setShowMessages(true);
    }
    prevInnerDivHeight.current = innerDivHeight;
  }, [children]);

  useEffect(() => {
    const { innerDivHeight, outerDivHeight } = getScrollDimensions();
    const outerDivScrollTop = Math.ceil(outerDiv.current.scrollTop);
    if (innerDivHeight > outerDivHeight && !sendMessageLoader) {
      setShowScrollButton(
        outerDivHeight + outerDivScrollTop < innerDivHeight - 5,
      );
    }
  }, [sendMessageLoader]);

  const handleScrollButtonClick = useCallback(() => {
    const { innerDivHeight, outerDivHeight } = getScrollDimensions();
    outerDiv.current.scrollTo({
      top: (innerDivHeight ?? 0) - (outerDivHeight ?? 0),
      left: 0,
      behavior: 'smooth',
    });

    setShowScrollButton(false);
    setShowBottomBtn(false);
  }, []);

  return (
    <Container isOpen={isOpen}>
      <OuterDiv ref={outerDiv}>
        <InnerDiv showMessages={showMessages} ref={innerDiv}>
          {children}
        </InnerDiv>
      </OuterDiv>
      <ScrollBottomBtn
        onClick={handleScrollButtonClick}
        style={{
          pointerEvents: showBottomBtn || showScrollButton ? 'auto' : 'none',
          opacity: showBottomBtn || showScrollButton ? 1 : 0,
        }}
      >
        <LeftCloseArrow width={28} height={28} />
      </ScrollBottomBtn>
    </Container>
  );
};
