export const validExtensions = [
  'jpg', // JPEG image format (widely supported)
  'jpeg', // JPEG image format (alternative MIME)
  'png', // PNG image format (widely supported)
  'gif', // GIF image format (widely supported)
  'webp', // WebP image format (widely supported in modern browsers)
  'svg', // Scalable Vector Graphics (widely supported)
  'avif', // AVIF image format (supported in most modern browsers),
  'bmp',
  'heic',
  'HEIC',
  'heif',
];
