import { isEmpty } from 'lodash/fp';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  doCreateChatGroup,
  doDeleteChatGroup,
  doGetChatSearchUsers,
  doGetChatUsers,
  doGetConversation,
  doSendMessage,
  doUpdateChatGroup,
  doUpdateChatUsers,
  doUpdateConversation,
} from 'store/actionCreators/chat';
import { get } from 'lodash/fp';
import { doCheckIsAuthorized } from 'store/actionCreators/auth';
import {
  createChatGroup,
  CreateChatGroupResponse,
  deleteChatGroup,
  getChatSearchUsers,
  getChatUsers,
  getConversation,
  sendMessage,
  updateChatGroup,
} from 'services/chat';

import { getCurrentUser } from 'services/auth';
import { ChatResponse } from 'store/reducers/chat';
import { getChat, getChatUser } from 'store/selectors/chat';
import { doUpdateTotalChatCount } from 'store/actionCreators/profile';

export function* onGetChatUsers({
  payload,
}: ReturnType<typeof doGetChatUsers>) {
  try {
    const userList: ChatResponse = yield call(getChatUsers, {
      userId: payload.userId,
      unseen: payload.type,
      communityId: payload.communityId,
    });

    yield put(
      doGetChatUsers.success({
        chats: userList.chats,
        forNotification: false,
      }),
    );
  } catch ({ response }) {
    yield put(
      doGetChatUsers.failure({
        response,
        forNotification: payload.forNotification,
      }),
    );
    if (get(['status'], response) === 401)
      yield put(doCheckIsAuthorized.trigger({}));
  }
}

export function* onGetChatSearchUsers({
  payload,
}: ReturnType<typeof doGetChatSearchUsers>) {
  try {
    const searchUserList: ChatResponse = yield call(getChatUsers, {
      userId: payload.userId,
      unseen: payload.type,
      search: payload.search,
      communityId: payload.communityId,
    });
    yield put(doGetChatSearchUsers.success(searchUserList));
  } catch ({ response }) {
    yield put(doGetChatSearchUsers.failure(response));
    if (get(['status'], response) === 401)
      yield put(doCheckIsAuthorized.trigger({}));
  }
}

export function* onSendMessage({ payload }: ReturnType<typeof doSendMessage>) {
  try {
    const response = yield call(sendMessage, payload);
    const existingConversations = yield select(getChat);
    const existingUser = yield select(getChatUser);
    const conversationExists = existingUser.some(
      (conversation) =>
        conversation?.chatId === response.chatId ||
        conversation?.id === response.chatId,
    );
    if (!conversationExists && existingConversations) {
      yield put(
        doGetChatUsers({
          userId: payload.userId,
          type: false,
          communityId: payload.communityId,
        }),
      );
    }

    if (!existingConversations) {
      yield put(
        doGetConversation.trigger({
          chatId: response.chatId,
          receiverId: payload.receiverId,
          userId: payload.userId,
        }),
      );
      yield put(
        doGetChatUsers.trigger({
          userId: payload.userId,
          communityId: payload.communityId,
          type: false,
        }),
      );
      yield put(doSendMessage.success({ successful: true }));
    } else {
      yield put(doSendMessage.success(response));
    }

    yield call(payload.onSuccess);
  } catch ({ response }) {
    yield put(doSendMessage.failure(response));
    if (get(['status'], response) === 401)
      yield put(doCheckIsAuthorized.trigger({}));
  }
}

export function* onGetConversation({
  payload,
}: ReturnType<typeof doGetConversation>) {
  try {
    const conversation = yield call(
      getConversation,
      payload.userId,
      payload.receiverId,
      payload.chatId,
      payload.limit,
      payload.page,
    );

    yield put(
      doGetConversation.success({
        conversation: conversation.chat,
        count: payload.count || null,
      }),
    );
    // const existingConversations = yield select(getChat);
    // const conversationExists = existingConversations.some(
    //   (conversation) => conversation.chat.id === payload.chatId,
    // );
    // if (!conversationExists) {
    //   yield put(
    //     doGetChatUsers({
    //       userId: payload.userId,
    //       type: false,
    //       communityId: payload.communityId,
    //     }),
    //   );
    // }
    // storeConversation(conversation.chat);

    if (payload?.count) {
      yield put(doUpdateTotalChatCount.success({ count: payload?.count }));
    }
  } catch ({ response }) {
    if (get(['status'], response) === 400) {
      yield put(doGetConversation.failure(response));
    }
    if (get(['status'], response) === 401)
      yield put(doCheckIsAuthorized.trigger({}));
  }
}

export function* onUpdateConversation({
  payload,
}: ReturnType<typeof doUpdateConversation>) {
  try {
    const conversation = yield call(
      getConversation,
      payload.userId,
      payload.receiverId,
      payload.chatId,
      payload.limit,
      payload.page,
    );
    yield put(doUpdateConversation.success(conversation));
  } catch ({ response }) {
    if (get(['status'], response) === 400) {
      yield put(doUpdateConversation.failure(response));
    }
    if (get(['status'], response) === 401)
      yield put(doCheckIsAuthorized.trigger({}));
  }
}

export function* onUpdateChatUsers({
  payload,
}: ReturnType<typeof doUpdateChatUsers>) {
  try {
    const user = yield call(getCurrentUser, payload.userId);
    const userData = {
      communityId: '',
      count: null,
      createdAt: user.profile.createAt,
      latestMessage: null,
      peerUserId: user.profile.userId,
      profile: {
        userId: user.profile.userId,
        firstName: user.profile.firstName,
        lastName: user.profile.lastName,
        profileName: user.profile.profileName,
        logo: user.profile.logo || '',
        smallLogo: user.profile.smallLogo,
      },
      updatedAt: user.profile.updatedAt,
    };
    if (!isEmpty(user.communities) || !isEmpty(user.groups)) {
      yield put(doUpdateChatUsers.success(userData));
    }
  } catch ({ response }) {
    yield put(doUpdateChatUsers.failure(response));
    if (get(['status'], response) === 401)
      yield put(doCheckIsAuthorized.trigger({}));
  }
}

export function* onCreateChatGroup({
  payload,
}: ReturnType<typeof doCreateChatGroup>) {
  try {
    const response: CreateChatGroupResponse = yield call(
      createChatGroup,
      payload.members[0],
      payload,
      payload.communityId,
    );
    yield put(doCreateChatGroup.success(response.chat));
    yield call(payload.onSuccess, response.chat);
  } catch ({ response }) {
    yield put(doCreateChatGroup.failure(response));
    if (get(['status'], response) === 401)
      yield put(doCheckIsAuthorized.trigger({}));
  }
}

export function* onUpdateChatGroup({
  payload,
}: ReturnType<typeof doUpdateChatGroup>) {
  try {
    const response = yield call(
      updateChatGroup,
      payload.userId,
      payload.chatId,
      payload.payload,
      payload.communityId,
    );
    yield put(doUpdateChatGroup.success(response));
    yield call(payload.onSuccess);
  } catch ({ response }) {
    yield put(doUpdateChatGroup.failure(response));
    if (get(['status'], response) === 401)
      yield put(doCheckIsAuthorized.trigger({}));
  }
}

export function* onDeleteChatGroup({
  payload,
}: ReturnType<typeof doDeleteChatGroup>) {
  try {
    const response = yield call(
      deleteChatGroup,
      payload.userId,
      payload.chatId,
    );
    if (response.success) {
      yield call(payload.onSuccess);
      yield put(doDeleteChatGroup.success({ chatId: payload.chatId }));
    }
  } catch ({ response }) {
    yield put(doDeleteChatGroup.failure(response));
    if (get(['status'], response) === 401)
      yield put(doCheckIsAuthorized.trigger({}));
  }
}

export default function* () {
  yield takeLatest(doGetChatUsers, onGetChatUsers);
  yield takeLatest(doGetChatSearchUsers, onGetChatSearchUsers);
  yield takeLatest(doGetConversation, onGetConversation);
  yield takeLatest(doUpdateConversation, onUpdateConversation);
  yield takeLatest(doUpdateChatUsers, onUpdateChatUsers);
  yield takeLatest(doSendMessage, onSendMessage);
  yield takeLatest(doCreateChatGroup, onCreateChatGroup);
  yield takeLatest(doUpdateChatGroup, onUpdateChatGroup);
  yield takeLatest(doDeleteChatGroup, onDeleteChatGroup);
}
