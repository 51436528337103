import { assoc, compose } from 'lodash/fp';
import { handleActions } from 'redux-actions';
import {
  doCreateChatGroup,
  doDeleteChatGroup,
  doGetChatSearchUsers,
  doGetChatUsers,
  doGetConversation,
  doSendMessage,
  doSetConversation,
  doUpdateChatGroup,
  doUpdateChatUsers,
  doUpdateConversation,
} from 'store/actionCreators/chat';

export type ChatUser = {
  userId: string;
  firstName: string;
  communityId?: string[];
  lastName: string;
  profileName: string;
  logo: string;
  smallLogo: string | null;
};
export type Type = 'text' | 'image' | 'file';
export type ReadStatus = {
  userId: string;
  readAt: string;
};

export type Messages = {
  communityId: string;
  id: string;
  senderId: string;
  content: string;
  type: Type;
  mediaUrl?: string[];
  createdAt: string;
  readStatus: ReadStatus[];
};

export type Message = {
  content: string;
  type: Type;
  mediaUrl?: string[];
  createdAt: string;
};

export type Chat = {
  id?: string;
  chatId?: string;
  communityId?: string[];
  chatName: string;
  lastMessageAt: string;
  lastMessage: Message;
  description: string;
  logo: string;
  admin?: string;
  peerUsers: ChatUser | null;
  isGroupChat: boolean;
  createdAt?: string;
  updatedAt?: string;
  chatUnreadCount: number;
};

export type ChatResponse = {
  success: boolean;
  chats: Chat[];
};

export type Conversation = {
  id: string;
  admin?: string;
  isGroupChat: boolean;
  chatName: string;
  communityId?: string[];
  lastMessageAt: string;
  logo?: string | null;
  description: string;
  createdAt?: string;
  updatedAt?: string;
  messageCount: number;
  messages: Messages[];
  peerUsers: ChatUser[];
};

export interface ChatState {
  unseenChatCount: number | null;
  chatUser: Chat[] | null;
  isOldChat: boolean;
  conversation: Conversation | null;
  chatNotifications: ChatUser[] | null;
  error: any;
  loaders: {
    chatUserLoading: boolean;
    conversationLoading: boolean;
    updateConversationLoading: boolean;
    searchLoading: boolean;
    chatNotificationLoader: boolean;
    sendMessageLoading: boolean;
    createGroupLoading: boolean;
    updateGroupLoading: boolean;
    deleteGroupLoading: boolean;
  };
}

const initialState: ChatState = {
  unseenChatCount: null,
  chatUser: null,
  conversation: null,
  isOldChat: true,
  error: null,
  chatNotifications: null,
  loaders: {
    chatUserLoading: false,
    conversationLoading: false,
    updateConversationLoading: false,
    searchLoading: false,
    chatNotificationLoader: false,
    sendMessageLoading: false,
    createGroupLoading: false,
    updateGroupLoading: false,
    deleteGroupLoading: false,
  },
};

export default handleActions<ChatState, any>(
  {
    [doGetChatUsers.TRIGGER]: (state, { payload }) => {
      if (payload.forNotification) {
        return compose(assoc(['loaders', 'chatNotificationLoader'], true))(
          state,
        );
      } else {
        return compose(assoc(['loaders', 'chatUserLoading'], true))(state);
      }
    },
    [doGetChatUsers.SUCCESS]: (state, { payload }) => {
      if (payload.forNotification) {
        return compose(
          assoc(['chatNotifications'], payload?.chats || []),
          assoc(['loaders', 'chatNotificationLoader'], false),
        )(state);
      } else {
        return compose(
          assoc(['chatUser'], payload?.chats || []),
          assoc(['loaders', 'chatUserLoading'], false),
        )(state);
      }
    },
    [doGetChatUsers.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['error'], payload),
        assoc(['loaders', 'chatUserLoading'], false),
      )(state),
    // create group
    [doCreateChatGroup.TRIGGER]: (state, { payload }) =>
      compose(
        assoc(['error'], payload),
        assoc(['loaders', 'createGroupLoading'], true),
      )(state),
    [doCreateChatGroup.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['chatUser'], [payload, ...(state.chatUser || [])]),
        assoc(['loaders', 'createGroupLoading'], false),
      )(state),
    [doCreateChatGroup.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['error'], payload),
        assoc(['loaders', 'createGroupLoading'], false),
      )(state),
    //search
    [doGetChatSearchUsers.TRIGGER]: (state) => compose()(state),
    // assoc(['loaders', 'chatUserLoading'], true),
    // assoc(['loaders', 'searchLoading'], true),
    [doGetChatSearchUsers.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['chatUser'], payload.chats),
        // assoc(['loaders', 'chatUserLoading'], false),
        // assoc(['loaders', 'searchLoading'], false),
      )(state),
    [doGetChatSearchUsers.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['error'], payload),
        // assoc(['loaders', 'chatUserLoading'], false),
        // assoc(['loaders', 'searchLoading'], false),
      )(state),
    //updateList
    [doUpdateChatUsers.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'chatUserLoading'], true))(state),
    [doUpdateChatUsers.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['chatUser'], [payload, ...state.chatUser]),
        assoc(['loaders', 'chatUserLoading'], false),
      )(state),
    [doUpdateChatUsers.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['error'], payload),
        assoc(['loaders', 'chatUserLoading'], false),
      )(state),
    [doGetConversation.TRIGGER]: (state) =>
      compose(
        assoc(['loaders', 'conversationLoading'], true),
        assoc(['isOldChat'], true),
      )(state),
    [doGetConversation.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(['conversation'], payload?.conversation),
        assoc(
          ['chatUser'],
          payload?.count
            ? state?.chatUser.map((i) =>
                i?.chatId === payload?.conversation.id ||
                i?.id === payload?.conversation.id
                  ? { ...i, chatUnreadCount: 0 }
                  : i,
              )
            : state?.chatUser,
        ),
        assoc(
          ['isOldChat'],
          payload?.conversation.messages.length > 0 ? true : false,
        ),
        assoc(['loaders', 'conversationLoading'], false),
      )(state),
    [doGetConversation.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['isOldChat'], false),
        assoc(['conversation'], null),
        assoc(['error'], payload.data.message),
        assoc(['loaders', 'conversationLoading'], false),
      )(state),
    [doUpdateConversation.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'updateConversationLoading'], true))(state),
    [doUpdateConversation.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(
          ['conversation', 'messages'],
          [...payload.chat.messages, ...state.conversation.messages],
        ),
        assoc(['loaders', 'updateConversationLoading'], false),
      )(state),
    [doUpdateConversation.FAILURE]: (state) =>
      compose(assoc(['loaders', 'updateConversationLoading'], false))(state),
    [doSetConversation.SUCCESS]: (state, { payload }) =>
      compose(assoc(['conversation'], payload))(state),
    //message
    [doSendMessage.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'sendMessageLoading'], true))(state),
    [doSendMessage.SUCCESS]: (state, { payload }) =>
      payload.successful
        ? compose(
            assoc(['loaders', 'sendMessageLoading'], false),
            assoc(['isOldChat'], true),
          )(state)
        : compose(
            (state) => {
              if (state.conversation.id === payload.chatId) {
                return assoc(
                  ['conversation', 'messages'],
                  [...state.conversation.messages, ...payload.messages],
                )(state);
              }
              return state;
            },
            assoc(['isOldChat'], true),
            assoc(['loaders', 'sendMessageLoading'], false),
          )(state),
    [doSendMessage.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['error'], payload),
        assoc(['loaders', 'sendMessageLoading'], false),
      )(state),
    //update group
    [doUpdateChatGroup.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'updateGroupLoading'], true))(state),
    [doUpdateChatGroup.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(
          ['chatUser'],
          state.chatUser.map((i) =>
            i.id === payload.chat.id || i.chatId === payload.chat.id
              ? payload.chat
              : i,
          ),
        ),
        assoc(['conversation'], {
          ...state.conversation,
          ...Object.fromEntries(
            Object.entries(payload.chat).filter(
              ([key]) => key in state.conversation,
            ),
          ),
        }),
        assoc(['loaders', 'updateGroupLoading'], false),
      )(state),
    [doUpdateChatGroup.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['error'], payload),
        assoc(['loaders', 'updateGroupLoading'], false),
      )(state),
    //delete group
    [doDeleteChatGroup.TRIGGER]: (state) =>
      compose(assoc(['loaders', 'deleteGroupLoading'], true))(state),
    [doDeleteChatGroup.SUCCESS]: (state, { payload }) =>
      compose(
        assoc(
          ['chatUser'],
          state.chatUser.filter((i) => i.chatId !== payload.chatId),
        ),
        assoc(['conversation'], null),
        assoc(['loaders', 'deleteGroupLoading'], false),
      )(state),
    [doDeleteChatGroup.FAILURE]: (state, { payload }) =>
      compose(
        assoc(['error'], payload),
        assoc(['loaders', 'deleteGroupLoading'], false),
      )(state),
  },
  initialState,
);
