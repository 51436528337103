import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useLocalStorage } from 'react-use';
import { css } from 'styled-components';
import { useToasts } from 'react-toast-notifications';
import Lottie from 'react-lottie';
import newIcon from 'static/animations/new.json';

import { COMMUNITY_ROUTES, ROUTES } from 'configs';
import { CommunityRoles, ModalTypes } from 'types';
import {
  CommunityTitle,
  Loader,
  MenuChatList,
  MenuItem,
  MenuList,
  MenuMembersList,
  SettingsButton,
  PendingSettingsInvite,
  Divider,
} from 'components';

import { doToggleMenu } from 'store/actionCreators/currentCommunity';
import { openModal } from 'store/actionCreators/modal';
import {
  getCommunitiesLoading,
  getCommunities,
} from 'store/selectors/communities';
import {
  getCurrentCommunity,
  getCurrentUserInCommunity,
  getIsMenuOpen,
} from 'store/selectors/currentCommunity';

import {
  StyledMenuBlock,
  StyledMenuHeader,
  StyledMenuItemsBlock,
  StyledSettingsLink,
  StyledBurger,
} from './styled';
import {
  MailIcon,
  HomePageIcon,
  GroupIcon,
  DocumentationIcon,
  ChatIcon,
  PushNotificationLogo,
} from 'static';
import { getChatUser, getChatUserLoading } from 'store/selectors/chat';
import { getMessageUnSeenCount } from 'store/selectors/auth';

export const animationOptions = {
  animationData: newIcon,
  autoplay: true,
  loop: true,
};
const CommunityMenu = () => {
  const { t } = useTranslation();
  const { communityId } = useParams();
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const community = useSelector(getCurrentCommunity);
  const communities = useSelector(getCommunities);
  const currentUser = useSelector(getCurrentUserInCommunity);
  const isMenuOpen = useSelector(getIsMenuOpen);
  const isCommunitiesLoading = useSelector(getCommunitiesLoading);
  const chatUser = useSelector(getChatUser);
  const chatUserLoading = useSelector(getChatUserLoading);
  const messageUnSeenCount = useSelector(getMessageUnSeenCount);
  const groups = community.groups || community.overallScheduler?.groups;
  const userRole = currentUser?.communityPermission.role;
  const { users = [] } = community;
  const [listMaxHeights, setListMaxHeights] = useState({
    groups: 0,
    members: 0,
    chats: 0,
  });
  const [menuState, setMenuState] = useLocalStorage<string>('menuShown', '');
  const navigate = useNavigate();
  const isLoading = isCommunitiesLoading;
  const invitesCount = communities.find((c) => c.id === community.id)?.requests
    ?.length;

  // Refs
  const groupsListRef = useRef(null);
  const membersListRef = useRef(null);
  const chatsListRef = useRef(null);
  const uniqueUsersRef = useRef([]);
  const previousChatUserRef = useRef([]);
  if (chatUser && chatUser.length > 0) {
    const newUniqueUsers = chatUser.slice(0, 3);
    const isRemoved =
      previousChatUserRef.current.length > newUniqueUsers.length;
    if (
      isRemoved ||
      JSON.stringify(previousChatUserRef.current) !==
        JSON.stringify(newUniqueUsers)
    ) {
      uniqueUsersRef.current = newUniqueUsers;
      previousChatUserRef.current = newUniqueUsers;
    }
  } else {
    uniqueUsersRef.current = [];
    previousChatUserRef.current = [];
  }

  const handleGroupEventPopupOpen = (data: string) => {
    if (data !== 'chat') {
      navigate(`${ROUTES.COMMUNITY}/${communityId}/overall`);
    }

    setTimeout(() => {
      dispatch(
        openModal({
          type:
            data === 'chat'
              ? ModalTypes.CHAT_POPUP
              : ModalTypes.COMMUNITY_POPUP,
        }),
      );
    }, 1500);
  };

  useEffect(() => {
    if (!menuState) {
      setMenuState('true');
      dispatch(doToggleMenu());
    }

    window.addEventListener('resize', setListsHeight);

    return () => window.removeEventListener('resize', setListsHeight);
  }, []);

  useEffect(() => {
    setListsHeight();
  }, [community]);

  // useEffect(() => {
  //   if (Array.isArray(chatUsers) && chatUsers.length > 0) {
  //     chatUsers.forEach((user) => {
  //       if (user.id === currentUser?.profile.id && chatsEnabled) {
  //         setAccessToChat(true);
  //       }
  //     });
  //   }
  // }, [chatUsers]);

  const setListsHeight = () => {
    let groupsListHeight =
      groupsListRef?.current?.getBoundingClientRect().height + 10 || 0;
    let chatsListHeight =
      chatsListRef?.current?.getBoundingClientRect().height || 0;
    let membersListHeight =
      membersListRef?.current?.getBoundingClientRect().height + 10 || 0;
    const heightLeft = window.innerHeight - 324;

    if (groupsListHeight + chatsListHeight + membersListHeight > heightLeft) {
      while (
        groupsListHeight + chatsListHeight + membersListHeight >
        heightLeft
      ) {
        if (
          groupsListHeight > (heightLeft - 324) / 2 &&
          groupsListHeight >= membersListHeight
        ) {
          groupsListHeight -= 5;
        }

        if (
          chatsListHeight > (heightLeft - 290) / 2 &&
          chatsListHeight >= membersListHeight
        ) {
          chatsListHeight -= 5;
        }

        if (
          membersListHeight > (heightLeft - 324) / 2 &&
          membersListHeight >= groupsListHeight
        ) {
          membersListHeight -= 5;
        }
      }
    }

    setListMaxHeights({
      groups: groupsListHeight,
      chats: chatsListHeight,
      members: membersListHeight,
    });
  };

  const handleMenuToggle = useCallback(() => {
    setMenuState(menuState ? 'true' : '');
    dispatch(doToggleMenu());
  }, [menuState, dispatch]);

  const commonMemberProps = useMemo(() => {
    const filteredUsers = users
      .filter((u) => u.communityPermission?.role !== CommunityRoles.BLOCKED)
      .map((li) => ({
        ...li,
        unreadCount:
          chatUser?.find((u) => u.peerUsers?.userId === li.id)
            ?.chatUnreadCount || 0,
      }));

    return {
      list: [],
      listRef: membersListRef,
      listMaxHeight: listMaxHeights.members,
      userRole,
      users: filteredUsers,
      groupList: groups,
    };
  }, [
    users,
    chatUser,
    membersListRef,
    listMaxHeights.members,
    userRole,
    groups,
  ]);

  const handleOnArrowClick = () => {
    navigate(`${ROUTES.COMMUNITY}/${community?.id}/overall/#members`);
  };

  return (
    <StyledMenuBlock isMenuOpen={isMenuOpen}>
      <StyledMenuHeader>
        {invitesCount > 0 &&
          [CommunityRoles.ADMIN, CommunityRoles.OWNER].includes(userRole) && (
            <PendingSettingsInvite
              invitesCount={invitesCount}
              addCss={{
                right: isMenuOpen ? 34 : 4,
                top: 6,
              }}
            />
          )}
        <CommunityTitle
          title={community ? community.name : ''}
          image={community ? community.logo : ''}
          isMenuOpen={isMenuOpen}
          frame
        />
        {isMenuOpen &&
        [CommunityRoles.ADMIN, CommunityRoles.OWNER].includes(userRole) ? (
          <StyledSettingsLink
            end
            to={`${ROUTES.COMMUNITY}/${community && community.id}/${
              COMMUNITY_ROUTES.SETTINGS
            }`}
          >
            <SettingsButton />
          </StyledSettingsLink>
        ) : null}
        <StyledBurger isMenuOpen={isMenuOpen} onClick={handleMenuToggle}>
          <div></div>
          <div></div>
          <div></div>
        </StyledBurger>
      </StyledMenuHeader>
      <Divider customMargin="0px 0px 16px 0px" />
      {community && !isLoading ? (
        <StyledMenuItemsBlock isMenuOpen={isMenuOpen}>
          <MenuItem
            title={t('menu.homePage')}
            icon={<HomePageIcon />}
            to={`${ROUTES.COMMUNITY}/${community.id}`}
          />
          <MenuList
            title={t('menu.groupScheduler')}
            titleLink={`${ROUTES.COMMUNITY}/${community.id}/${COMMUNITY_ROUTES.OVERALL}`}
            icon={<GroupIcon />}
            list={community.groups}
            isPlusIcon
            isAddButtonContained
            userRole={userRole}
            listRef={groupsListRef}
            listMaxHeight={listMaxHeights.groups}
            onClick={handleGroupEventPopupOpen}
            communityId={community.id}
          />
          <MenuItem
            to={`${ROUTES.COMMUNITY}/${community.id}/${COMMUNITY_ROUTES.DOCUMENTATION}`}
            title={t('menu.documentation')}
            icon={<DocumentationIcon />}
          />
          <MenuItem
            to={`${ROUTES.COMMUNITY}/${community.id}/${COMMUNITY_ROUTES.PUSH_NOTIFICATION}`}
            title={t('pushNotification.header')}
            // RightIcon={
            //   <Lottie options={animationOptions} width={31} height={31} />
            // }
            isAddButtonContained
            icon={<PushNotificationLogo />}
            toolTipText={t('pushNotification.plusTooltip')}
            isAdminOrOwner={[
              CommunityRoles.ADMIN,
              CommunityRoles.OWNER,
            ].includes(userRole)}
            onClick={() => {
              addToast(t('pushNotification.toasterText'), {
                appearance: 'success',
                autoDismiss: false,
              });
            }}
            iconStyles={css`
              width: 14px;
              height: 14px;
              position: relative;
              left: -1px;
            `}
            menuBlockStyles={{ gap: '8px' }}
          />
          <MenuItem
            to={`${ROUTES.COMMUNITY}/${community.id}/${COMMUNITY_ROUTES.EMAIL}`}
            title={t('common.email')}
            icon={<MailIcon />}
            toolTipText={t('email.plusTooltip')}
            isAdminOrOwner={[
              CommunityRoles.ADMIN,
              CommunityRoles.OWNER,
            ].includes(userRole)}
            isAddButtonContained
            onClick={() => {}}
          />
          <MenuItem
            to={`${ROUTES.COMMUNITY}/${community.id}/${COMMUNITY_ROUTES.CHAT}`}
            title={t('menu.chats')}
            icon={<ChatIcon />}
            isAddButtonContained
            RightIcon={
              <Lottie options={animationOptions} width={31} height={31} />
            }
            toolTipText={t('chat.plusTooltip')}
            rightIconRight="50px"
            onClick={handleGroupEventPopupOpen}
            count={messageUnSeenCount}
            iconStyles={css`
              width: 14px;
              height: 14px;
              position: relative;
              left: -1px;
            `}
          />
          <MenuChatList
            commonMember={uniqueUsersRef.current}
            chatUserLoading={chatUserLoading}
            communityId={community.id}
            listMaxHeight={listMaxHeights.chats}
            listRef={chatsListRef}
            onClick={handleGroupEventPopupOpen}
          />
          <MenuMembersList
            handleOnArrowClick={handleOnArrowClick}
            showArrow
            to={`${ROUTES.COMMUNITY}/${community?.id}/overall/#members`}
            {...commonMemberProps}
          />
        </StyledMenuItemsBlock>
      ) : (
        isMenuOpen && <Loader />
      )}
    </StyledMenuBlock>
  );
};

export default CommunityMenu;
