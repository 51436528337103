import styled from 'styled-components';
import { breakpoints } from 'theme';

export const CreateChatWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const PushMessage = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: row;
  padding: 12px 12px 36px 12px;
  justify-content: center;
`;
export const Images = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
  align-items: flex-start;
  width: 100%;

  ${({ disabled }) =>
    disabled && {
      opacity: '0.6',
      pointerEvents: 'none',
    }};
  p {
    color: ${({ theme }) => theme.color.pickledBluewood};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    text-align: left;
  }
`;

PushMessage.Input = styled.div`
  flex: 1;
  display: flex;
  width: calc(100% - 80px);
  justify-content: center;
  flex-direction: column;
  transition: all 0.35s;
  max-width: 800px;
  padding: 0px 12px 0px 12px;
  align-items: flex-start;
  @media ${breakpoints.downLgPlus} {
    max-width: ${({ isOpen }) => (isOpen ? '520px' : '620px')};
  }
  textarea {
    border-width: 2px;
  }
  & > div:first-child {
    width: 100%;
  }
`;

export const ButtonWrapper = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  margin-top: 19px;
  width: auto;
  gap: 18px;
`;

export const Label = styled.label`
  flex-direction: column;
`;

export const CloseIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
`;
