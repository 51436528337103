// util functions exclusive to push notification
import i18n from 'i18next';
import moment from 'moment';
import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getAvailableActiveCommunities } from 'store/selectors/communities';
// new dimensions for more spacing
const PUSH_FORM_WIDTH = 320 + 60;
const MOBILE_WIDTH = 340 + 60;
const TEMPLATES_WIDTH = 320;

export const getDisplayFormat = (
  currentWidth: number,
): 'single' | 'double' | 'triple' => {
  const currentScreenWidth = window.innerWidth;
  const isDownMd = currentScreenWidth < 960 && currentScreenWidth > 600;
  const extraPadding = isDownMd ? 30 : 0;
  if (currentWidth) {
    if (currentWidth < PUSH_FORM_WIDTH + MOBILE_WIDTH - 60 + extraPadding) {
      //subtract 60 because only 2 elements and gap is 60px only.
      return 'single';
    } else if (
      currentWidth <
      PUSH_FORM_WIDTH + MOBILE_WIDTH + TEMPLATES_WIDTH
    ) {
      return 'double';
    } else if (
      currentWidth >=
      PUSH_FORM_WIDTH + MOBILE_WIDTH + TEMPLATES_WIDTH
    ) {
      return 'triple';
    }
  }
};

export const useDateTime = () => {
  const [dateTime, setDateTime] = useState<{ date: string; time: string }>({
    date: '',
    time: '',
  });

  useEffect(() => {
    const trackTime = setInterval(() => {
      const language = i18n.language;
      const today = moment().locale(language);
      const values = {
        date: today.format('dddd, D MMMM'),
        time: today.format('HH:mm'),
      };
      setDateTime(values);
    }, 10000);

    return () => {
      clearInterval(trackTime);
    };
  }, []);

  return dateTime;
};

export const useHideImage = ({
  title,
  message,
}: {
  title: string;
  message: string;
}) => {
  return useMemo(() => {
    const newlineCount = ((message + title).match(/\n/g) || []).length;

    if (title.length > 60 || message.length > 100 || newlineCount > 3) {
      return true;
    } else {
      return false;
    }
  }, [title, message]);
};

export const useCommunityNamesById = (ids: string[]) => {
  const communities = useSelector(getAvailableActiveCommunities);

  const message = ids
    ?.map((id) => {
      const community = communities.find((c) => c.id === id);
      return community ? community.name : null;
    })
    .filter(Boolean)
    .join(', ');

  return message || '';
};
