import React, { useState, useRef, useEffect, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { closeModal } from 'store/actionCreators/modal';
import { modalSelector } from 'store/selectors/modal';
import { useSelector } from 'react-redux';
import { Sidebar, SidebarHeader, SidebarContent } from 'components';
import { IconWrapper, SkeletonWrapper } from './styled';
import { IconDownload } from 'static';
import { MediaRenderer } from '.';
import { Container } from '../styled';
import { FileViewData } from 'types';
import {
  CellMeasurer,
  CellMeasurerCache,
  Masonry,
  createMasonryCellPositioner,
} from 'react-virtualized';
import ImageMeasurer from 'react-virtualized-image-measurer';
import { validExtensions } from './utils';
import { generateRandomRgbaColor } from 'utils';

const columnWidth = 176;
const defaultHeight = 132;
const defaultWidth = columnWidth;

const cache = new CellMeasurerCache({
  defaultHeight,
  defaultWidth,
  fixedWidth: true,
});

const cellPositionerConfig = {
  cellMeasurerCache: cache,
  columnCount: 2,
  columnWidth,
  spacer: 8,
};
const cellPositioner = createMasonryCellPositioner(cellPositionerConfig);
const keyMapper = (item, index) => item.image || index;

const MasonryComponent = ({
  itemsWithSizes,
  setRef,
  handleImageClick,
  handleDownloadImage,
  t,
  setIsLoading,
  isLoading,
}) => {
  // Local state to store the items that are already rendered
  const [renderedItems, setRenderedItems] = useState([]);

  // Update the local state when new items arrive
  useEffect(() => {
    if (itemsWithSizes.length > renderedItems.length) {
      // Add only new items to the existing list
      setRenderedItems((prevItems) => [
        ...prevItems,
        ...itemsWithSizes.slice(prevItems.length),
      ]);
    }
  }, [itemsWithSizes, renderedItems]);

  const cellRenderer = ({
    index,
    key,
    parent,
    style,
  }: {
    index: number;
    key: string | number;
    parent: any;
    style?: React.CSSProperties;
  }) => {
    // Render only if there are items to render
    if (!Array.isArray(renderedItems) || renderedItems.length === 0) {
      return null;
    }

    const { item, size } = renderedItems[index];
    if (!!item && isLoading) {
      setIsLoading(false);
    }
    const cleanItem = item.split('?noCache')[0];
    const name = cleanItem.split('/').pop() || '';
    const type = name.split('.').pop()?.toLowerCase() || '';
    const isImage = validExtensions.includes(type);

    const height = isImage
      ? columnWidth * (size.height / size.width) || defaultHeight
      : '100%';

    return (
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      <CellMeasurer cache={cache} index={index} key={key} parent={parent}>
        <div style={style}>
          {item && (
            <Container
              key={index}
              style={{
                height: height,
                width: columnWidth,
                display: 'block',
                background: generateRandomRgbaColor(),
                overflow: 'hidden',
              }}
            >
              <IconWrapper onClick={(e) => handleDownloadImage(e, cleanItem)}>
                <IconDownload width="16" height="16" />
                <p>{t('common.download')}</p>
              </IconWrapper>
              <MediaRenderer
                mediaUrl={cleanItem}
                handleImageClick={handleImageClick}
              />
            </Container>
          )}
        </div>
      </CellMeasurer>
    );
  };

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Masonry
      cellCount={renderedItems.length}
      cellMeasurerCache={cache}
      cellPositioner={cellPositioner}
      cellRenderer={cellRenderer}
      height={2000}
      width={376}
      autoHeight
      keyMapper={keyMapper}
      ref={setRef}
    />
  );
};

const MemoizedMasonryComponent = memo(MasonryComponent);

const FileView = () => {
  const refMasonry = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data } = useSelector(modalSelector);
  const modalData = data as FileViewData;

  const cleanCatch = () => {
    refMasonry?.current?.clearCellPositions();
    cache.clearAll();
    cellPositioner.reset(cellPositionerConfig);
  };

  const handleClose = () => {
    cleanCatch();
    dispatch(closeModal());
  };

  useEffect(() => {
    return () => {
      cleanCatch();
    };
  }, []);

  return (
    <Sidebar>
      <SidebarHeader label="Images and Files" onCloseClick={handleClose} />
      <SidebarContent fullHeight>
        {isLoading && (
          <SkeletonWrapper>
            {Array.from({ length: modalData.urls.length }).map((_, index) => (
              <div
                key={index}
                className="skeleton skeleton-box"
                style={{ height: '145px' }}
              />
            ))}
          </SkeletonWrapper>
        )}
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment  */}
        {/* @ts-ignore */}
        <ImageMeasurer
          items={
            modalData.urls.length
              ? modalData.urls.map((url) => url + '?noCache=' + Math.random())
              : []
          }
          image={(item) => item}
          keyMapper={keyMapper}
          onError={(error, item, src) => {
            console.error(
              'Cannot load image',
              src,
              'for item',
              item,
              'error',
              error,
            );
          }}
          defaultHeight={defaultHeight}
          defaultWidth={defaultWidth}
        >
          {({ itemsWithSizes }) => {
            return (
              <MemoizedMasonryComponent
                setRef={refMasonry}
                itemsWithSizes={itemsWithSizes}
                handleImageClick={modalData.handleImageClick}
                handleDownloadImage={modalData.handleDownloadImage}
                t={t}
                setIsLoading={setIsLoading}
                isLoading={isLoading}
              />
            );
          }}
        </ImageMeasurer>
      </SidebarContent>
    </Sidebar>
  );
};

export default FileView;
